<template>
    <div class="max-w-5xl w-full mx-auto text-left grid gap-4">
        <div><Link href="/stories" class="text-nomad-200 hover:text-nomad-accent flex items-center font-bold"><span class="icon-chevron-left text-2xl"></span> <span>STORIES</span></Link></div>
        <h1 class="text-white text-3xl lg:text-4xl font-bold" v-html="story.title"></h1>
        <div class="flex flex-col gap-1 text-nomad-50 font-light">
            <div>{{ story.author }}</div>
            <div class="uppercase text-sm">{{ story.published_at }}</div>
        </div>
        <div v-if="story.image">
            <img :src="story.image" class="rounded-lg w-full" alt="">
        </div>

        <div v-for="block in story.blocks" >
            <div class="text-nomad-50 max-w-3xl grid gap-4 text-lg" v-if="block.type == 'paragraph'">
                <p class="break-words" v-html="block.data.text"></p>
            </div>
            <div v-if="block.type == 'heading'">
                <div class="text-white font-semibold my-3" v-html="block.data.text"></div>
            </div>
            <div v-if="block.type == 'image'">
                <div class="max-w-5xl grid gap-2 text-lg">
                    <img :src="block.data.url" :alt="block.data.text" class="rounded-md">
                    <div class="block text-nomad-100 text-sm" v-html="block.data.text"></div>
                </div>
            </div>
        </div>
        <div class="text-nomad-100">
            <span v-if="story.source"><a :href="story.source_article" target="_blank" class="text-nomad-500 hover:text-nomad-accent">{{ story.source.name }} <span class="icon-external-link"></span></a></span>
        </div>
    </div>
</template>
<script setup>
import { Link, usePage, router } from "@inertiajs/vue3";
const { story } = usePage().props;
</script>