<script>
import { h } from "vue";
import { Link } from "@inertiajs/vue3";
export default {
    name: "Tile",
    props: {
        title: String,
        subtitle: String,
        url: {
            type: String,
            defualt: null
        }
    },
    render() {
        const children = [h("div", { class: "flex-1" }, [h("h2", {
            class: "text-nomad-200 text-xl text-medium",
            innerHTML: this.title,
        }),
        h("h3", {
            class: "text-nomad-400 font-thin text-lg",
            innerHTML: this.subtitle,
        }),],),]
        if (this.url) {
            children.push(h(Link, { href: this.url, class: "icon-arrow-right px-4 text-2xl text-nomad-400 hover:text-white transition-all" }, []))
        }
        return h("div", { class: "w-full my-6 mx-3 flex items-center" }, children);
    },
};
</script>
