<template>
    <!-- /3336232/300//250 -->
    <div
        id="leader-board"
        class="w-full overflow-hidden m-auto px-0 sm:px-6 lg:px-8 text-center  transition-all mb-2"
    >
    </div>
</template>
<script>
export default {
    data() {
        return {
            isRendered: false
        }
    },
    mounted(){
        googletag.cmd.push(function() { googletag.display('leader-board'); });
    },
    created() {
        if(window.googletag && googletag.apiReady)
            googletag.pubads().refresh();
    }
}
</script>