<script>
import { h } from "vue";
import Tile from "@/components/Tile.vue";
import CardArtist from '@/components/CardArtist.vue'
export default {
    name: "PopularArtists",
    props: {
        artists: {
            type: Array,
            default: [],
        },
    },
    render() {
        return h("div", {class: 'w-full'}, [
            h(Tile, {
                title: "Trending artists",
                subtitle: "top played artists",
            }),
            h("ul", {class: 'grid gap-3'},
            this.artists.map((artist) =>
                 h("li", h(CardArtist, {artist: artist, type: 'horizontal'})))
            ),
        ]);
    },
};
</script>
