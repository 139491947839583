<template>
    <div>
        <div class="text-white my-6">
            <form @submit.prevent="getContent">
                <input
                    type="search"
                    v-model="q"
                    placeholder="What are you in the mood to hear?"
                    class="w-full placeholder-nomad-100 bg-nomad-800 h-24 rounded-3xl mb-8 focus:outline-none focus:shadow-outline focus:bg-nomad-100 focus:text-nomad-900 focus:placeholder-nomad-300 focus-text text-xl lg:text-2xl px-8 focus:shadow-lg border-0"
                    :disabled="laoding"
                />
            </form>
        </div>
        
        <popular-genres :playlists="homepage.playlists" v-if="!q" />

        <div v-if="laoding">
            <grid-artists-placeholder cards="4" />
        </div>
        <div v-else>
            <div v-if="message">
                <h3 class="font-thin text-nomad-300 text-center">{{ message  }}</h3>
            </div>
            <div
                class="w-full grid grid-cols-2 md:grid-cols-3 lg:grid-cols-7 gap-4 mb-8"
            >
                <div v-for="artist in artists" :key="artist.id">
                    <card-artist :artist="artist" />
                </div>
            </div>
            <div
                class="w-full grid grid-cols-2 md:grid-cols-3 lg:grid-cols-5 gap-4 mb-8"
                v-if="albums.length"
            >
                <div v-for="album in albums" :key="album.id">
                    <album-card :album="album" />
                </div>
            </div>
            <div
                class="w-full grid grid-cols-1 md:grid-cols-3 lg:grid-cols-4 gap-4"
            >
                <div v-for="song in songs" :key="song.id">
                    <card-song :song="song" />
                </div>
            </div>
            <div
                class=" w-full grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4 my-3" v-if="podcasts"
            >
                <div v-for="podcast in podcasts" :key="podcast.id">
                    <podcast-card :podcast="podcast" />
                </div>
            </div>
            <div class="flex gap-3 text-sm font-light" v-if="pagination">
                <button class="text-nomad-accent flex items-center border rounded-lg p-3" v-if="pagination.current_page > 1" @click="prevPage"><span class="icon-chevron-left"></span> PREV</button>
                <button class="text-nomad-accent flex items-center border rounded-lg p-3" v-if="pagination.current_page < pagination.last_page" @click="nextPage">NEXT <span class="icon-chevron-right"></span></button>
            </div>
            <div class="my-4 px-2">
                <div class="flex gap-3 text-nomad-400 font-light text-sm"><div>Search powered by Algolia</div><img src="/assets/algolia.png" width="24" alt="Algolia"></div>
            </div>
        </div>
    </div>
</template>

<script setup></script>

<script>
import CardArtist from "@/components/CardArtist.vue";
import { defineAsyncComponent } from 'vue'
import {
    ArtistPlaceholder,
    GridArtistsPlaceholder,
} from "@/components/placeholders/placeholder.js";
import CardSong from "@/components/CardSong.vue";
import AlbumCard from "@/components/AlbumCard.vue";
import { router } from "@inertiajs/vue3";
import axios from "axios";
import { mapState, mapActions, } from "vuex";
import PopularGenres from "@/components/PopularGenres.vue";

export default {
    inject: ["current"],

    provide() {
        return {
            stream: this.$root.$data,
        };
    },
    data() {
        return {
            q: null,
            laoding: false,
            albums: [],
            songs: [],
            artists: [],
            podcasts: [],
            contents: [],
            message: null,
            pagination: null,
            page: 0,
        };
    },
    created() {
        this.prepareHomepage();
    },
    beforeMount() {
        document.title = "Search for Somali Music & Lyrics"
    },
    methods: {
        ...mapActions("homepage", ["prepareHomepage"]),
        scrollToTop() {
            document.getElementById('app').scrollIntoView({ behavior: 'smooth' });
        },
        nextPage() {
            if(this.pagination && this.pagination.current_page < this.pagination.last_page) {
                this.page = ++this.pagination.current_page
                this.scrollToTop()
                this.getContent()
            }
        },
        prevPage() {
            if(this.pagination && this.pagination.current_page > 1) {
                this.page = --this.pagination.current_page
                this.scrollToTop()
                this.getContent()
            }
        },
        getContent() {
            this.laoding = true;
            this.message = null;
            axios
                .get("/api/searchy", { params: { query: this.q, page: this.page } })
                .then((response) => {
                    this.pagination = response.data.pagination;
                    this.albums = response.data.albums;
                    this.songs = response.data.songs;
                    this.artists = response.data.artists;
                    this.podcasts = response.data.podcasts;
                    this.message = response.data.message ?? null
                    this.laoding = false;
                }).catch((error) => {});
        },
    },
    computed: {
        ...mapState({
            homepage: (state) => state.homepage.all,
        }),
    },
    components: {
        "card-artist": CardArtist,
        "card-song": CardSong,
        "grid-artists-placeholder": GridArtistsPlaceholder,
        "popular-genres": PopularGenres,
        AlbumCard,
        'podcast-card':  defineAsyncComponent(() => import('@/components/PodcastCard.vue'))
    }
};
</script>
