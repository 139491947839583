<template>
    <Link
        :href="'/song/' + song.id"
        class="w-full hover:bg-nomad-accent text-nomad-200 hover:text-nomad-900 bg-nomad-800  h-20 items-center grid grid-cols-3 p-3 focus:outline-none rounded-md"
    >
        <div class="col-span-2">
            <h2
                class="text-ellipsis overflow-hidden whitespace-nowrap"
            >
                {{ song.name }}
            </h2>
            <h3
                class=" text-md font-thin text-ellipsis overflow-hidden whitespace-nowrap"
                v-if="song.artist"
            >
                {{ song.artist.name }}
            </h3>
        </div>
        <div class="text-right flex gap-3 place-content-end">
            <span class="font-thin text-nomad-400" v-if="song.formatted_duration">{{ song.formatted_duration  }}</span>
        </div>
    </Link>
</template>
<script>
import { mapState, mapActions } from "vuex";
import { Link } from "@inertiajs/vue3";
export default {
    name: "CardSong",
    props: {
        song: Object,
    },
    components: {
        Link,
    },
    methods: {
        ...mapActions("favorites", [""]),
        ...mapActions("current", ["prepareCurrent"]),
    },
};
</script>
