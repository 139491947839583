<template>
    <div>
        <div class="bg-nomad-900 items-center flex rounded-md p-3 h-32">
            <h1 class="text-white uppercase my-auto font-bold text-3xl">Favorites</h1>
        </div>
        <div class="w-full grid grid-cols-1 gap-4 mt-6">
            <div v-for="song in favorites" :key="song.id">
                    <card-song :song="song" />
            </div>
        </div>
    </div>
</template>
<script>
import CardSong from "@/components/CardSong.vue";
import { mapState, mapActions } from "vuex";
export default {
    provide() {
        return {
            stream: this.$root.$data,
            playSong: this.$root.playSong,
        };
    },
    components: { "card-song": CardSong },
    computed: mapState({
        favorites: (state) => state.favorites.all,
    }),
    methods: {
        ...mapActions("favorites", ["addToFavorites"]),
    },
    mounted() {
        this.$store.dispatch("favorites/getAllFavorites");
    },
    beforeMount() {
        document.title = "Favorites"
    },
};
</script>
