<template>
    <Container>
        <div class="w-full flex my-4 gap-3">
            <Link
                href="/backstage/artists"
                class="text-white rounded-md py-2 px-3 bg-nomad-900 hover:bg-nomad-800"
                >Cancel</Link
            >
        </div>
        <form @submit.prevent="submit" class="grid gap-3 max-w-md">
            <div>
                <input
                    type="text"
                    v-model="form.name"
                    class="rounded-md w-full"
                    placeholder="event name/title"
                />
            </div>
            <div>
                <input
                    type="text"
                    v-model="form.venue"
                    class="rounded-md w-full"
                    placeholder="venue name"
                />
            </div>
            <div>
                <input
                    type="text"
                    v-model="form.price"
                    class="rounded-md w-full"
                    placeholder="price"
                />
            </div>
            <div>
                <Selector type="artists" placeholder="original singer" v-model="form.artists" max="10" />
            </div>
            
            <div>
                <textarea class="rounded-md w-full" rows="5" v-model="form.description" placeholder="description"></textarea>
            </div>
            <div><ImageUploader v-model="form.poster" /></div>
            <div>
                <input
                    type="text"
                    v-model="form.street"
                    class="rounded-md w-full"
                    placeholder="street address"
                />
            </div>
            <div>
                <input
                    type="text"
                    v-model="form.city"
                    class="rounded-md w-full"
                    placeholder="city"
                />
            </div>
            <div>
                <select v-model="form.country_id" class="rounded-md w-full">
                    <option v-for="country in props.countries" :key="country.id" :value="country.id">{{ country.name }}</option>
                </select>
            </div>
            <div>
                <input
                    type="text"
                    v-model="form.postal_code"
                    class="rounded-md w-full"
                    placeholder="postal code"
                />
            </div>
            <div>
                <input
                    type="text"
                    v-model="form.ticket_url"
                    class="rounded-md w-full"
                    placeholder="ticket website"
                />
            </div>
            <div>
                <input
                    type="datetime-local"
                    v-model="form.date_at"
                    class="rounded-md w-full"
                    placeholder="show date and time"
                />
            </div>
            <div>
                <input
                    type="datetime-local"
                    v-model="form.published_at"
                    class="rounded-md w-full"
                    placeholder="show date and time"
                />
            </div>
            <div class="block">
                <button class="text-nomad-900 bg-nomad-accent text-xl rounded-md py-2 px-4">Create</button>
            </div>
        </form>
        <pre class="text-nomad-100">{{ form }}</pre>
    </Container>
</template>
<script setup>
import Container from "@/Pages/Backstage/Container.vue";
import { Link, usePage, useForm } from "@inertiajs/vue3";
import ImageUploader from "@/Pages/Backstage/Components/ImageUploader.vue";
import Selector from "@/Pages/Backstage/Components/Selector.vue";
const props = usePage().props
const form = useForm({
    name: null,
    venue: null,
    price: null,
    artists: null,
    description: null,
    poster: null,
    street: null,
    city: null,
    country_id: null,
    postal_code: null,
    ticket_url: null,
    date_at: null,
    published_at: null
})

function submit() {
    form.post('/backstage/events')
}
</script>