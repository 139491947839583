<template>
    <div class="w-full hover:bg-nomad-800 bg-nomad-900 p-3 rounded">
        <Link
            :href="'/artist/' + artist.id"
            class="text-center grid gap-2 focus:outline-none"
            v-if="type === 'vertical'"
        >
            <img
                :src="artist.image"
                class="rounded-3xl h-32 bg-cover w-32 m-auto object-cover"
                :alt="artist.name"
            />
            <h2
                class="text-white text-md text-ellipsis overflow-hidden whitespace-nowrap"
            >
                {{ artist.name }}
            </h2>
            <p class="text-white text-md font-thin">
                {{ artist.songs_count }} songs
            </p>
        </Link>
        <Link v-else :href="'/artist/' + artist.id" class="text-left flex items-center gap-4 focus:outline-none">
            <div class="">
                <img
                :src="artist.image"
                class="rounded-3xl h-20 bg-cover w-20 m-auto object-cover"
                alt=""
            />
            </div>
            <div class="">
                <h2
                    class="text-white text-md text-ellipsis overflow-hidden whitespace-nowrap"
                >
                    {{ artist.name }}
                </h2>
                <p class="text-white text-md font-thin">
                    {{ artist.songs_count }} songs
                </p>
            </div>
        </Link>
    </div>
</template>
<script setup>
defineProps({ artist: Object, type: { type: String, default: "vertical" } });
</script>
<script>
import { Link } from "@inertiajs/vue3";
export default {
    components: {
        Link,
    },
};
</script>
