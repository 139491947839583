<template>
    <div class="w-full my-3 min-h-48">
        <Carousel
            v-if="!contents"
            :items-to-show="3"
            :breakpoints="breakpoints"
            :wrapAround="true"
            :transition="500"
        >
            <Slide v-for="placeholder in placeholders" :key="placeholder.id">
                <SlidePlaceholder class="w-full m-3" />
            </Slide>
        </Carousel>
        <Carousel
            v-else
            :items-to-show="3"
            :breakpoints="breakpoints"
            :wrapAround="true"
            :transition="500"
        >
            <Slide v-for="feature in contents" :key="feature.id">
                <Link :href="'/song/' + feature.id" class="w-full m-3 focus:outline-none">
                    <div class="w-full">
                        <img
                            :src="feature.cover"
                            loading="lazy"
                            class="w-full rounded-3xl object-cover h-64"
                            :alt="feature.name"
                        />
                        <h1 class="text-nomad-200 my-2 text-lg lg:text-2xl">{{ feature.name }}</h1>
                        <h3 class="text-nomad-300 text-md lg:text-xl font-thin">
                            {{ feature.artist.name }}
                        </h3>
                    </div>
                </Link>
            </Slide>
        </Carousel>
    </div>
</template>
<script>
import { Carousel, Slide, Pagination, Navigation } from "vue3-carousel";
import "vue3-carousel/dist/carousel.css";
import { Link } from "@inertiajs/vue3";
import { SlidePlaceholder } from "@/components/placeholders/placeholder.js";
export default {
    props: {
        contents: Array,
    },
    data() {
        return {
            placeholders: [{ id: 1 }, { id: 2 }, { id: 3 }, { id: 4 }],
            // breakpoints are mobile first
            // any settings not specified will fallback to the carousel settings
            breakpoints: {
                // 700px and up
                100: {
                    itemsToShow: 1.2,
                    snapAlign: "center",
                },
                700: {
                    itemsToShow: 2.2,
                    snapAlign: "center",
                },
                // 1024 and up
                1024: {
                    itemsToShow: 3,
                    snapAlign: "start",
                },
            },
        };
    },
    components: {
        Carousel,
        Slide,
        Pagination,
        Navigation,
        Link,
        SlidePlaceholder,
    },
};
</script>
<style>
.carousel__pagination-button.carousel__pagination-button--active:after {
    @apply rounded bg-nomad-300;
}
.carousel__pagination-button:after {
    @apply rounded bg-nomad-600;
}
</style>
