import SongApi from '@/api/song.js'
import UserApi from '@/api/user.js'
import FavoriteApi from '@/api/favorite.js'
import CurrentApi from '@/api/current.js'
import HomepageApi from '@/api/homepage.js'
import EventApi from '@/api/event.js'
import GenreApi from '@/api/genre.js'
import AlbumApi from '@/api/album.js'
import JustAddedApi from '@/api/just_added.js'
import PodcastApi from '@/api/podcast.js'
import EpisodeApi from '@/api/episode.js'
import PlaylistApi from '@/api/playlist.js'
const api = {
    SongApi,
    UserApi,
    FavoriteApi,
    CurrentApi,
    HomepageApi,
    EventApi,
    GenreApi,
    AlbumApi,
    JustAddedApi,
    PodcastApi,
    EpisodeApi,
    PlaylistApi
}
export default api