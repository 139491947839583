<template>
    <Container>
        <div class="w-full flex my-4 gap-3">
            <Link
                href="/backstage/artists"
                class="text-white rounded-md py-2 px-3 bg-nomad-900 hover:bg-nomad-800"
                >Cancel</Link
            >
        </div>
        <form @submit.prevent="submit" class="grid gap-2">
            <div>
                <input
                    type="text"
                    v-model="form.name"
                    class="rounded-md"
                    placeholder="song name"
                />
            </div>
            <div>
                <Selector
                    type="artists"
                    v-model="form.artist"
                    placeholder="select artist"
                />
            </div>
            <div>
                <Selector
                    type="artists"
                    placeholder="select featuring artist"
                    v-model="form.features"
                    max="4"
                />
            </div>
            <div>
                <Selector type="artists" placeholder="original singer" v-model="form.original_artist" />
            </div>
            <div>
                <Selector type="playlists" placeholder="playlist" v-model="form.playlists" max="10" />
            </div>
            <div>
                <input
                    type="number"
                    v-model="form.released"
                    class="rounded-md"
                    placeholder="released"
                />
            </div>
            <div>
                <AudioUploader v-model="form.audio" />
            </div>
            <div>
                <input
                    type="text"
                    v-model="form.youtube"
                    class="rounded-md"
                    placeholder="youtube id"
                />
            </div>
            
            <div>
                <YoutubeImage :youtube="form.youtube" v-model="form.cover" v-model:thumbnail="form.meta.thumbnail" />
            </div>
            <div class="text-white flex items-center my-2">
               <label for="is_audio_download"> <input type="checkbox" class="rounded appearance-none" id="is_audio_download" v-model="form.meta.is_youtube" value="1" /> <span>download audio from youtube id</span></label>
            </div>
            <div class="text-white flex items-center my-2">
               <label for="is_audio_download"> <input type="checkbox" class="rounded appearance-none" id="is_audio_download" v-model="form.meta.retry" value="1" /> <span>Retry audio process</span></label>
            </div>
            <div class="text-white flex items-center my-2">
               <label for="is_smart_music"> <input type="checkbox" class="rounded appearance-none" id="is_smart_music" v-model="form.meta.is_smart" value="1" /> <span>Smart Music?</span></label>
            </div>
            <div class="block"><button class="text-nomad-900 bg-nomad-accent text-xl rounded-md py-2 px-4">Create</button></div>
        </form>
        <pre class="text-white overflow-auto w-full max-w-md">{{ form }}</pre>
    </Container>
</template>
<script setup>


import Container from "@/Pages/Backstage/Container.vue";
import Selector from "@/Pages/Backstage/Components/Selector.vue";
import YoutubeImage from "@/Pages/Backstage/Components/YoutubeImage.vue";
import AudioUploader from "@/Pages/Backstage/Components/AudioUploader.vue";
import { Link, useForm, usePage } from "@inertiajs/vue3";
const form = useForm({
    name: null,
    artist: null,
    original_artist: null,
    features: null,
    album: null,
    playlists: null,
    released: null,
    youtube: null,
    lyrics: null,
    cover: null,
    audio: null,
    meta: {
        thumbnail: null,
        is_youtube: true,
        cover: false,
        retry: false,
        is_smart: false
    },
});

function submit() {
    form.post('/backstage/songs')
}
</script>
