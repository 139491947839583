<template>
  <div class="fixed bottom-20 w-full text-center z-50" v-if="show">
    <button
      class="bg-black/50 m-auto rounded-full  flex gap-2 items-center p-3 inline-block appearance-none shadow-md transition-all ease-in cursor-pointer hover:bg-black/80"
      :class="['notification', type]" @click="close" v-if="message" aria-label="Dismiss notification">
      <h6 class="">{{ message }}</h6>
      <span class="icon-x"></span>
    </button>
  </div>
</template>
  
<script setup>
import { computed } from 'vue';
import { useStore } from 'vuex';

const store = useStore();

const show = computed(() => store.state.notification.show);

const message = computed(() => store.state.notification.message);

let type = computed(() => store.state.notification.type);

const close = () => {
  store.dispatch('notification/closeNotification');
};
</script> 
<style >
.notification {
  @apply font-medium;
}

.notification.warning {
  @apply bg-yellow-600;
  @apply text-nomad-900;
}

.notification.success {
  @apply bg-nomad-green;
  @apply text-nomad-100;
}

.notification.error {
  @apply bg-nomad-red;
  @apply text-nomad-100;
}

.notification.info {
  @apply bg-black/50;
  @apply text-nomad-accent;
}
</style>