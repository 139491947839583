<template>
    <Apps v-if="page && page.slug =='apps'" :contents="page" />
    <div v-else-if="page" class="w-full">
        <div class="w-full max-w-5xl wysiwyg bg-white p-3 rounded mx-auto">
            <h1 class="text-2xl">{{ page.title }}</h1>
            <div class=" wysiwyg  wysiwyg-red" v-html="page.content">             
            </div>
            <div class="font-thin my-3">last updated: {{ page.updated_at }}</div>
        </div>
    </div>
</template>

<script>
import { Link, usePage } from "@inertiajs/vue3";
import { mapState, mapActions, mapGetters } from "vuex";
import CardSong from '@/components/CardSong.vue'
import Apps from '@/Pages/Apps.vue'
export default {
    data() {
        return {
            page: null
        }
    },
    created() {
        this.page = usePage().props.page;
    },
    components: {
        Apps
    }
}
</script>