<template>
    <Container class="w-full">
        <div class="w-full grid grid-cols-1 md:grid-cols-2 gap-4">
            <FeaturedHome />
            <PlaylistConnection />
            <NoAudios />
            <YoutubeImport />
            <YoutubeUrls />
        </div>
    </Container>
</template>
<script>
import { defineAsyncComponent } from 'vue'
import Container from "@/Pages/Backstage/Container.vue";
export default {
    components: {
    Container,
    FeaturedHome: defineAsyncComponent(() => import('./Components/FeaturedHome.vue')),
    NoAudios: defineAsyncComponent(() => import('./Components/NoAudios.vue')),
    YoutubeImport: defineAsyncComponent(() => import('./Components/YoutubeImport.vue')),
    PlaylistConnection: defineAsyncComponent(() => import('./Components/PlaylistConnection.vue')),
    YoutubeUrls: defineAsyncComponent(() => import('./Components/YoutubeUrls.vue')),
}
};
</script>
