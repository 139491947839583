import { createStore, createLogger } from "vuex"
import FavoritesModule from "@/store/favorites.module.js"
import UserModule from "@/store/user.module.js"
import CurrentModule from "@/store/current.module.js"
import HomepageModule from "@/store/homepage.module.js"
import EventeModule from "@/store/event.module.js"
import GenreModule from "@/store/genre.module.js"
import NotificationModule from "@/store/notification.module.js"
import AlbumModule from "@/store/album.module.js"
import JustAddedModule from "@/store/justadded.module.js"
import PodcastModule from "@/store/podcast.module.js"
import EpisodeModule from "@/store/episode.module.js"
import PlaylistModule from "./playlist.module"

const debug = process.env.NODE_ENV !== 'production'

const store = createStore({
    modules: {
        favorites: FavoritesModule,
        user: UserModule,
        current: CurrentModule,
        homepage: HomepageModule,
        event: EventeModule,
        genre: GenreModule,
        notification: NotificationModule,
        album: AlbumModule,
        justadded: JustAddedModule,
        podcast: PodcastModule,
        episode: EpisodeModule,
        playlists: PlaylistModule,
        playlist: PlaylistModule
    },
    strict: debug,
    plugins: debug ? [createLogger()] : []
});

export default store;
