<script>
import { h } from "vue";
import Tile from "@/components/Tile.vue";
import CardSong from "@/components/CardSong.vue";
export default {
    name: "PopularSongs",
    props: {
        songs: {
            type: Array,
            default: [],
        },
    },
    render() {
        return h("div", {class: 'grid gap-2'}, [
            h(Tile, { title: "Top songs", subtitle: "top played this week" }),
            this.songs.map((song) => h(CardSong, { song: song.song })),
        ]);
    },
};
</script>
