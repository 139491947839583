<template>
    <Container>
        <div class="w-full flex my-4 gap-3">
            <Link href="/backstage/story/create"
                class="text-nomad-accent rounded-md py-2 px-3 bg-nomad-700 hover:bg-nomad-600">Create</Link>
            <Link href="/backstage" class="text-white rounded-md py-2 px-3 bg-nomad-900 hover:bg-nomad-800">Cancel
            </Link>
        </div>
        <div class="w-full flex my-4 gap-3">
            <input type="text" v-model="form.url" class="rounded-lg">
            <button @click="getStory" class="rounded-lg bg-nomad-900 text-nomad-accent border border-nomad-accent font-medium px-3 py-2 hover:bg-nomad-accent hover:text-nomad-900 flex gap-2"><span v-if="loading" class="icon-loader"></span><span>import story</span></button>
        </div>
        <div class="text-nomad-red" v-if="message" v-html="message"></div>

        <div class="w-full">
            <ul v-if="stories && stories.data" class="grid gap-3">
                <li v-for="story in stories.data" :key="story.id"
                    class="p-3 bg-nomad-800 rounded-3xl text-white flex flex-col lg:flex-row gap-4 items-center">
                    <div>
                        <img :src="story.image" alt="" class="w-32 rounded-md">
                    </div>
                    <div class=" flex-auto">
                        <Link :href="'/backstage/story/' + story.id + '/edit'">
                            <h2 class="text-md lg:text-xl text-nomad-50">
                                {{ story.title }}
                            </h2>

                            <span class="text-nomad-400 mr-2" v-if="story.source">{{ story.source.name }}</span>
                            <div class="flex gap-2 text-xs my-2">
                                <span class=" rounded-md text-black px-2"
                                    :class="{ 'bg-nomad-green': (story.extra?.text_formatted ), 'bg-nomad-50': !story.extra?.text_formatted  }">TEXT FORMATTED</span>
                                    <span class=" rounded-md text-black px-2"
                                    :class="{ 'bg-nomad-green': (story.extra?.image_imported ), 'bg-nomad-50': !story.extra?.image_imported  }">IMAGE IMPORTED</span>
                            </div>
                        </Link>
                    </div>
                    <div class="flex gap-4">
                        <button class="bg-nomad-light rounded-lg text-nomad-900 px-2" @click.prevent="publish(story.id)" v-if="!story.published_at">publish</button>
                        <button class="bg-nomad-accent rounded-lg text-nomad-900 px-2" @click.prevent="unpublish(story.id)" v-else>un publish</button>
                        <button class="icon-x rounded-full bg-nomad-red text-white p-1"
                            :class="{ 'bg-nomad-green': story.published_at }"></button>
                        <a :href="story.source_article" target="_blank"
                            class="icon-link rounded-full bg-nomad-accent text-black p-1"
                            :class="{ 'bg-nomad-green': story.published_at}"></a>
                    </div>
                </li>
            </ul>
        </div>
    </Container>
</template>
<script setup>
import Container from "@/Pages/Backstage/Container.vue";
import { Link, usePage, router } from "@inertiajs/vue3";
const { stories } = usePage().props;
</script>
<script>
import axios from 'axios'
export default {
    data() {
        return {
            form: {
                url: null,
            },
            loading: false,
            message: null
        }
    },
    methods: {
        publish(id) {
            axios.post('/backstage/story/publish', {id: id})
                .then((response) =>  {
                    router.reload({ only: ['stories'] })
                })
            
        },
        unpublish(id){
            axios.post('/backstage/story/unpublish', {id: id})
                .then((response) =>  {
                    router.reload({ only: ['stories'] })
                })
        },
        getStory() {
            this.loading = true;
            this.message = null
            axios.post('/api/backstage/get-story', this.form)
                .then((response) => {
                    this.loading = false;
                    this.message = 'Story Imported.';
                    this.form.url = null;
                    router.reload({ only: ['stories'] })
                })
                .catch((error) => {
                    this.loading = false;
                    this.message = error;
                })
        }
    }
}
</script>