import axios from 'axios'
const FavoriteApi = {
    async add(id) {
       const song = await axios.put('/api/favorite/' + id)
       return song
    },
    async delete(id) {

    },

    async all() {
        const song = await axios.get('/api/favorite')
        return song
    }
}

export default FavoriteApi