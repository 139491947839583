<script>
import { h } from "vue";
import { Link } from '@inertiajs/vue3'
export default {
    name: "EventCard",
    props: {
        event: Object,
    },
    method:{

    },
    render() {
        return h("div", { class: "w-full relative rounded-3xl overflow-hidden cursor-pointer focus:outline-none" }, 
            h(Link, {href: '/event/' + this.event.id}, [
            h("img", {
                class: "object-cover h-64 w-full hover:scale-150 transition-all duration-300 ease-in-out transform",
                src: this.event.poster,
                alt: this.event.name
            }),
            h("div", {class: 'absolute bottom-0 left-0 px-4 py-4 bg-black/80 hover:bg-black/90 text-left rounded-3xl m-2'}, [
                h(
                    "h2",
                    { class: "text-nomad-200 font-medium text-sm md:text-md truncate text-clip" },
                    this.event.name
                ),
                h("h3", { class: "text-nomad-300 font-thin text-sm md:text-md" }, this.event.city),
            ]),
            ])
        );
    }, 
};
</script>
