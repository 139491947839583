<template>
    <div>
        <div class="w-full bg-nomad-900 bg-no-repeat bg-cover bg-center mt-3 rounded-lg overflow-hidden" :style="{ backgroundImage: 'url(' + artist.image + ')' }">
            <div class="backdrop-blur-md bg-nomad-900/50 flex items-center gap-3">
                <div class="flex-1 max-w-md"><img :src="artist.image" class="max-h-64" alt=""></div>
                <div class="flex-1 flex flex-col gap-1">
                    <span class="font-thin text-nomad-100">{{ artist.content_type }}</span>
                    <h1 class="text-left text-xl lg:text-5xl font-bold text-white uppercase">{{ artist.name }}</h1>
                    <h6 class="text-nomad-300">{{ artist.songs.length }} songs</h6>
                </div>
            </div>
        </div>
        <div class="grid grid-cols-1 lg:grid-cols-3 gap-4 my-3">
            <div>
                <div v-if="artist.biography" class="overflow-hidden transition-all" :class="{
                    'h-64 bg-nomad-800/20 rounded-lg relative': !expanded,
                    'border-0 ': expanded,
                }" @click="expandBio">
                    <div v-html="artist.biography" class="wysiwyg font-light text-md text-nomad-100 p-3"
                        :class="{ absolute: !expanded }"></div>
                    <div class="bg-gradient-to-t from-nomad-900 absolute h-16 w-full bottom-0" v-if="!expanded"></div>
                </div>
                <button v-if="artist.biography" aria-label="biography" class="text-nomad-300" :class="{
                    'icon-arrow-down': !expanded,
                    'icon-arrow-up': expanded,
                }" @click="expandBio"></button>
            </div>

            <div class="col-span-2">
                <div class="grid grid-cols-2 md:grid-cols-4 gap-6">
                    <event-card v-for="event in artist.events" :key="event.id" :event="event" :class="{'opacity-30': !event.is_upcoming}"/>
                </div>
                <h2 class="text-white text-xl font-thin mb-3 text-nomad-200" v-if="artist.albums.length">
                    Albums
                </h2>
                <div>
                    <div class="grid grid-cols-2 md:grid-cols-4 gap-6">
                        <album-card :album="album" v-for="album in artist.albums" :key="album.id" />
                    </div>
                </div>
                <h2 class="text-white text-xl font-thin mb-3 text-nomad-200">
                    Songs
                </h2>
                <div class="w-full grid grid-cols-1 gap-4" v-if="artist && artist.songs">
                    <div v-for="song in artist.songs" :key="song.id">
                        <card-song :song="song" />
                    </div>
                </div>
                <h2 class="text-white text-xl font-thin mb-3 text-nomad-200 mt-8" v-if="artist.appears.length">
                    Appears on
                </h2>
                <div class="w-full grid grid-cols-1 gap-4" v-if="artist && artist.appears">
                    <div v-for="song in artist.appears" :key="song.id">
                        <card-song :song="song" />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
defineProps({ artist: Object });
</script>

<script>
import CardSong from "@/components/CardSong.vue";
import AlbumCard from "@/components/AlbumCard.vue";
import EventCard from '@/components/EventCard.vue';
import { defineAsyncComponent } from 'vue'
export default {
    inject: ["current"],
    provide() {
        return {
            stream: this.$root.$data,
        };
    },
    data() {
        return {
            expanded: false,
        };
    },
    components: {
        CardSong,
        AlbumCard,
        EventCard,
        'adsense' : defineAsyncComponent(() => import("@/components/Adsense.vue")),
    },
    methods: {
        expandBio() {
            this.expanded = !this.expanded;
        },
    },
    beforeMount() {
        document.title = this.artist.name
    }
};
</script>
