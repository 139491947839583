<template>
    <div v-if="episode">
        <div class=" items-center flex rounded-md p-3 mb-3">
                <div class="text-center m-auto">
                    <h1 class="text-white font-thin text-3xl md:text-5xl">{{ episode.title }}</h1>
                    <h2 class="block text-white text-xl mt-2 font-thin"><Link :href="'/podcast/' + episode.podcast_id">{{  episode.podcast.title }}</Link></h2>
                </div>
            </div>
        <div class="grid grid-cols-1 lg:grid-cols-3 gap-4 my-3">
            
            <div class="flex gap-3 flex-col">
                <img :src="episode.image ?? episode.podcast.image" alt="" class="rounded-3xl w-full">
                <hr class="border-nomad-accent border-1 w-20 mx-auto" />
                <p class="text-lg text-nomad-500 text-center font-thin">{{ episode.podcast.host }}</p>
            </div>
            <div class="md:col-span-2 flex flex-col gap-2">
                <div class="rounded-lg bg-nomad-900 ">
                <div class="flex p-2 gap-3 items-center">
                    <div><button class="icon-play rounded-full bg-nomad-accent w-14 h-14 text-nomad-900  top-0 -right-2 focus:outline-none text-2xl hover:scale-125 transition-all hover:border border-nomad-accent font-bold" @click="prepareCurrent({id:episode.id, type: 'EPISODE'})"></button></div>
                    <div class="flex-1">
                        <p class="font-thin text-md text-nomad-accent"  v-if="episode.ordering">#{{ episode.ordering }}</p>
                        <h3 class="text-nomad-accent font-medium  text-lg">{{  episode.pubDate }}</h3>
                    </div>
                    <div  v-if="episode.source">
                        <p class="font-thin text-md text-nomad-accent">{{ episode.source[0].length }}</p>
                    </div>
                </div>
            </div>
                <hr class="border-nomad-accent my-3 border-1" />
                <p class="text-white text-xl font-thin break-words" v-html="episode.description"></p>
            </div>
        </div>
    </div>
</template>
<script setup>
defineProps({ episode: Object });
</script>
<script>
import { Link, usePage } from "@inertiajs/vue3";
import { defineAsyncComponent } from 'vue'
import { mapState, mapActions } from "vuex";
import EpisodeCard from "@/components/EpisodeCard.vue";
export default {
    methods: {
        ...mapActions("current", ["prepareCurrent"]),
    },
    beforeMount() {
        document.title = this.episode.title 
    },
    components: {
        EpisodeCard,
        'adsense' : defineAsyncComponent(() => import("@/components/Adsense.vue")),
    }
};
</script>
