import api from "@/api";
const NotificationModule = {
    state: {
        show: false,
        message: "",
        type: "info", // could be 'info', 'warning', 'success', 'error', etc.
    },
    mutations: {
        SET_NOTIFICATION(state, payload) {
            state.show = payload.show;
            state.message = payload.message;
            state.type = payload.type;
        },
    },
    actions: {
        showNotification({ commit }, payload) {
            commit("SET_NOTIFICATION", {
                show: true,
                message: payload.message,
                type: payload.type,
            });
            setTimeout(() => {
                commit("SET_NOTIFICATION", {
                    show: false,
                    message: "",
                    type: "info",
                });
            }, 5e3);
        },
        closeNotification({ commit }) {
            commit("SET_NOTIFICATION", {
                show: false,
                message: "",
                type: "info",
            });
        },
    },
    namespaced: true,
};

export default NotificationModule;
