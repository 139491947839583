import api from '@/api'
const GenreModule = {
    state: () => ({ single: {} }),
    mutations: {
        UPDATE_GENRE(state, payload) {
            state.single = payload
        }
    },
    actions: {
        async getSignleGenre(context, payload) {
            const content = await api.GenreApi.getSingle(payload);
            context.commit("UPDATE_GENRE", content.data);
        },
    },
    getters: {

    },
    namespaced: true,
}

export default GenreModule