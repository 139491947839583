<template>
    <div>
        <div class="mb-10">
            <h1 class="text-white font-thin text-center text-3xl md:text-5xl">Podcasts</h1>
            <p class="block text-white font-thin text-center">popular Somali podcasts</p>
        </div>
        
        <div v-if="podcasts" class="w-full grid md:grid-cols-2 lg:grid-cols-3 gap-10">
            <div v-for="podcast in podcasts">
                <div
                    class="w-full focus:outline-none text-center"
                >
                    <Link :href="'/podcast/' + podcast.id"
                        ><img
                            :src="podcast.image"
                            class="object-cover h-64 w-full rounded-3xl"
                        />
                        <h2
                            class="text-white text-md my-1"
                        >
                            {{ podcast.title }}
                        </h2>
                        <h4 class=" my-0 text-nomad-400 font-thin text-lg">{{ podcast.host  }}</h4>
                        <span class="text-nomad-600 font-thin" v-if="podcast.episodes_count">{{ podcast.episodes_count }} Episodes</span>
                        </Link
                    >
                </div>
            </div>
        </div>
    </div>
</template>
<script setup>
defineProps({ podcasts: Object });
</script>
<script>
import { defineAsyncComponent } from 'vue'
import { Link } from "@inertiajs/vue3";
export default {
    components: {
        Link,
        'adsense' : defineAsyncComponent(() => import("@/components/Adsense.vue")),
    },
    beforeMount() {
        document.title = "Podcasts"
    },
}</script>