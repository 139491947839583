<template>
    <Container>
        <div class="w-full flex my-4 gap-3">
            <Link
                href="/backstage/artists/create"
                class="text-nomad-accent rounded-md py-2 px-3 bg-nomad-700 hover:bg-nomad-600"
                >Create</Link
            >
            <Link
                href="/backstage"
                class="text-white rounded-md py-2 px-3 bg-nomad-900 hover:bg-nomad-800"
                >Cancel</Link
            >
        </div>
        <div class="w-full my-4">
            <input
                type="text"
                placeholder="search artist"
                class="rounded-md border-0 outline-0"
            />
        </div>
        <div class="w-full">
            <ul v-if="artists && artists.data" class="grid gap-3">
                <li
                    v-for="artist in artists.data"
                    :key="artist.id"
                    class="p-3 bg-nomad-900 rounded-3xl text-white"
                >
                    <Link :href="'/backstage/artists/' + artist.id + '/edit'"
                        ><h2 class="text-xl text-nomad-200">{{ artist.name }}</h2> <span class="text-nomad-600">{{ artist.songs_count }} songs</span></Link
                    >
                </li>
            </ul>
        </div>
    </Container>
</template>
<script>
import Container from "@/Pages/Backstage/Container.vue";
import { Link, usePage } from "@inertiajs/vue3";
import axios from "axios";
export default {
    data() {
        return {
            query: null,
            artists: usePage().props.artists,
        };
    },
    components: {
        Container,
        Link,
    },
    created() {
        this.getContents();
    },
    methods: {
        getContents() {
            // axios
            //     .get("/api/backstage/artists")
            //     .then((response) => (this.artists = response.data));
        },
    },
};
</script>
