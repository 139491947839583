<template>
    <Container>
        <div class="w-full flex my-4 gap-3">
            <Link
                href="/backstage/pages"
                class="text-white rounded-md py-2 px-3 bg-nomad-900 hover:bg-nomad-800"
                >Cancel</Link
            >
        </div>
        <form @submit.prevent="submit" class="grid gap-3 max-w-md">
            <div>
                <input
                    type="text"
                    v-model="form.title"
                    class="rounded-md w-full"
                    placeholder="title"
                />
            </div>
            <div class="block w-full mb-10">
                <textarea
                    v-model="form.content"
                    class="text-white w-full h-10 rounded-md bg-black"
                ></textarea>
            </div>

            <div class="block flex gap-2 mt-16">
                <button class="text-nomad-900 bg-nomad-accent text-xl rounded-md py-2 px-4">Update page</button>
                <Link class="text-nomad-accent bg-nomad-900 text-xl rounded-md py-2 px-4" :href="'/pages/' + page.slug">Preview</Link>
            </div>
        </form>
        <pre class="text-nomad-100">{{ form }}</pre>
    </Container>
</template>
<script setup>
import Container from "@/Pages/Backstage/Container.vue";
import { Link, usePage, useForm } from "@inertiajs/vue3";

const {page} = usePage().props;
const toolbarOptions = [
  ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
  ['blockquote'],

  [{ 'header': 1 }, { 'header': 2 }],               // custom button values
  [{ 'list': 'ordered'}, { 'list': 'bullet' }],
  [{ 'script': 'sub'}, { 'script': 'super' }],      // superscript/subscript
  [{ 'indent': '-1'}, { 'indent': '+1' }],          // outdent/indent
  [{ 'header': [2, 3, 4, 5, 6, false] }],
  [{ 'color': [] }, { 'background': [] }],          // dropdown with defaults from theme
  [{ 'align': [] }],
  ['clean']                                         // remove formatting button
];
const form = useForm({
    title: page.title,
    content: page.content,
});

function submit() {
    form.put("/backstage/pages/" + page.id);
}
</script>