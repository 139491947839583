<template>
    <div class="w-full hover:bg-nomad-accent text-nomad-200 hover:text-nomad-900 bg-nomad-900 grid gap-2 p-4">
        <h6 class="font-thin">{{ episode.pubDate }}</h6>
        <h1 class="text-lg break-all">
            <Link :href="'/episode/' + episode.id">{{ episode.title }}</Link>
        </h1>
        <p class="text-md font-thin break-all">{{ episode.excerpt }}</p>
        <div class="flex items-center gap-2">
            <button class="border hover:bg-nomad-900 hover:text-nomad-accent rounded-3xl px-3 py-1"
                aria-label="Play Episode"
                @click="prepareCurrent({ id: episode.id, type: 'EPISODE' })">
                <span>Play</span> <i class="icon-play"></i>
            </button>
            <span class="font-thin">{{ episode.source[0].length }}</span>
        </div>
    </div>
</template>
<script>
import { Link } from "@inertiajs/vue3";
import { mapState, mapActions } from "vuex";
export default {
    name: "EpisodeCard",
    props: {
        episode: Object,
    },
    components: {
        Link,
    },
    methods: {
        ...mapActions("current", ["prepareCurrent"]),
    },
};
</script>
