import api from '@/api'
const UserModule = {
    state: () => ({ auth: {}, isLoggedIn: false }),
    mutations: {
        UPDATE_AUTH(state, payload){
            state.isLoggedIn = true
            state.auth = payload.data
        },
        LOGOUT_AUTH(state) {
            state.auth = {},
            state.isLoggedIn = false
        }
    },
    actions: {
        async getUser(context) {
            const payload = await api.UserApi.getUser()
            context.commit("UPDATE_AUTH", payload);
        },
        async logoutUser(context) {
            const payload = await api.UserApi.logoutUser()
            context.commit("LOGOUT_AUTH");
        }
    },
    getters: {},
    namespaced: true
}
export default UserModule 