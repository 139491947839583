import "./bootstrap";
import { createApp, h, computed, ref } from "vue";
import { createInertiaApp, usePage, router } from "@inertiajs/vue3";
import { defineAsyncComponent } from 'vue'
const { result } = defineAsyncComponent(() => import('lodash'))
import { has } from 'lodash'
import store from "@/store";
import { mapActions, mapState } from "vuex";
import VueGtag, { useGtag } from "vue-gtag-next";

import Container from "@/Layouts/Container.vue";
import Ads from "@/components/Ads.vue";

router.on('navigate', (event) => {
    const { pageview } = useGtag()
    pageview({ page_path: event.detail.page.url })
})

createInertiaApp({
    progress: {
        // The delay after which the progress bar will appear
        // during navigation, in milliseconds.
        delay: 0,

        // The color of the progress bar.
        color: "#AEE200",

        // Whether to include the default NProgress styles.
        includeCSS: true,

        // Whether the NProgress spinner will be shown.
        showSpinner: false,
    },
    resolve: (name) => {
        const pages = import.meta.glob("./Pages/**/*.vue", { eager: true });
        let page = pages[`./Pages/${name}.vue`];
        page.default.layout = page.default.layout || Container;
        return page;
    },
    setup({ el, App, props, plugin }) {
        const app = createApp({
            render: () => h(App, props),
            data() {
                return {
                    queue: [],
                    current: {},
                    favorites: [],
                    //siteData: computed(() => usePage().props),
                };
            },
            computed: {
                siteData() {
                    return usePage().props
                }
            },
            setup() { },
            mounted() {
                axios.interceptors.response.use(
                    this.axiosResponse,
                    this.axiosError
                );
                if (has(this.siteData, "tokens.token")) {
                    localStorage.setItem("_token", this.siteData.tokens.token);
                }

                axios.interceptors.request.use(function (config) {
                    const token = "Bearer " + localStorage.getItem("_token");
                    config.headers.Authorization = token;
                    return config;
                });

                if (localStorage.getItem("_token")) this.getUser();
            },

            methods: {
                ...mapActions("user", ["getUser"]),
                computed: mapState({
                    auth: (state) => state.user.auth,
                }),
                axiosResponse(res) {
                    try {
                        // check for response code 123 and redirect to login

                        return Promise.resolve(res);
                    } catch (e) {
                        // check for response code 123 and redirect to login
                        return Promise.resolve(res);
                    }
                },
                axiosError(err) {
                    try {
                        // check for response code 123 and redirect to login
                        if (err.response.status === 401) {
                            this.notify(
                                "Unauthenticated",
                                "Please login to continue"
                            );
                        }
                        err = Object.assign(new Error(), {
                            message: err.response.data,
                        });
                    } catch (e) {
                        // check for response code 123 and redirect to login
                        // Will return err if something goes wrong

                    }
                    return Promise.reject(err);
                },
                playSong(id) {
                    axios.post("/api/_song/", { id: id }).then((response) => {
                        const mp3 = result(
                            response.data.data,
                            "sources[0].src"
                        );
                        if (has(response.data.data, "sources[0].src") && mp3) {
                            this.current = response.data.data;
                        } else {
                            this.notify(
                                "Error",
                                "Song is not available at the moment"
                            );
                        }
                    });
                },
            },
        });
        app.use(VueGtag, { isEnabled: true, useDebugger: false, property: { id: "G-60K0TQ0Z3Z", params: { send_page_view: true, } } });
        app.use(plugin);
        app.use(store);
        app.component('ads', Ads)
        app.mount(el);
    },
});
