<script>
import { h } from "vue";
import { Link } from '@inertiajs/vue3'
export default {
    name: "AlbumCard",
    props: {
        album: {
            type: Object,
            default: {},
        },
    },
    render() {
        return h(
            'div',
            {class: 'bg-nomad-900  overflow-hidden  '},
            h(Link, {href: '/album/' + this.album.id, 'class': 'focus:outline-none'}, [
                h('img', {src: this.album.cover, class: ' w-full'},),
                h('div', {class: 'text-center'}, [h('h3', { class: 'text-nomad-100 font-medium pt-3'}, this.album.name),
                h('h4', {class: 'text-nomad-200 font-thin pb-3'}, this.album.released_at)])
            ])
        );
    },
};
</script>
