<template>
    <div class="w-full">
        <Navigation />
        <Notification />
        <div class="container mx-auto my-24 p-3 lg:p-0">
            <slot></slot>
            <Footer />
        </div>
        
        <Transition
            enter-active-class="duration-500 ease-in"
            enter-from-class="transform opacity-0"
            enter-to-class="opacity-100"
            leave-active-class="duration-500 ease-in"
            leave-from-class="opacity-100"
            leave-to-class="transform opacity-0"
            ><keep-alive><Player ref="player" :key="1" /></keep-alive
        ></Transition>
    </div>
</template>
<script setup>
import Player from "@/Layouts/Player.vue";
import Navigation from "@/Layouts/Navigation.vue";
import Footer from "@/Layouts/Footer.vue";
import Notification from "@/components/Notification.vue";

import {onMounted, ref} from 'vue'
const player = ref()

onMounted(()=>{

})

function setVolume() {

}
</script>

