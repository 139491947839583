import axios from 'axios'
const PlaylistApi = {
    async getAllPlaylist(id) {
       const playlsits = await axios.get('/playlists/')
       return playlsits
    },

    async getSinglePlaylist(id) {
        const playlsits = await axios.get('/playlist/' + id)
        return playlsits
     }
}

export default PlaylistApi