<template>
    <div class="w-full fixed bottom-0" v-if="currentSong" :key="currentSong.id">
        <!-- <ads-player v-if="currentSong && currentSong.id" class="mb-9" /> -->
        <Affiliates v-if="currentSong && currentSong.id"  :class="[!hasVerse && currentSong.verse.length ? 'mb-12' : 'mb-2']" />
        <div v-if="currentSong.verse && currentSong.verse.length" :class="[!hasVerse ? 'h-0' : 'h-72']"
            class="bg-nomad-accent rounded-2xl max-w-7xl mx-auto -mb-10 z-30 overflow-scroll no-scrollbar transition-all ease-in">
            <div v-html="currentSong.verse[0].lyrics.non_synced_lyrics.original" class="text-center text-md mb-20 p-4 whitespace-pre-line"></div>
        </div>
        <div class="w-full h-20 z-40 text-white bg-nomad-primary flex flex-col gap-0" v-if="currentSong && currentSong.id">
            <div class="w-full flex">
                <input type="range" step="1" v-model="progress" min="0" max="1000"
                    class="w-full appearance-none bg-nomad-accent h-1 cursor-pointer range-xd accent-nomad-accent"
                    v-on:change="onSeek" @mousedown="onDragging" @mouseup="onDragging" />

            </div>
            <div class="flex items-center bg-nomad-primary max-w-7xl w-full m-auto">
                <div class="flex-none w-14">
                    <div class="w-10 h-10 rounded-lg mx-3  flex items-middle" v-if="loading"><span
                            class="icon-circle animate-ping text-nomad-accent m-auto"></span></div>
                    <img :src="currentSong.artist.image" class="w-10 h-10 rounded-lg mx-3 object-contain" alt="" v-else />
                </div>
                <div class="flex-none w-14">
                    <button class="text-3xl mx-4" @click="playPause"
                        :class="[isPlaying ? 'icon-pause' : 'icon-play']"></button>
                </div>
                <div class="grow p-2 flex flex-col items-center">
                    <div class="inline-block">
                        <h1 class="text-sm md:text-md line-clamp-1 m-0">
                            <Link :href="'/episode/' + currentSong.id" v-if="currentSong.content_type == 'EPISODE'">{{ currentSong.name }}</Link>
                            <Link :href="'/song/' + currentSong.id" v-else>{{ currentSong.name }}</Link>
                        </h1>
                    </div>
                    <h2 class="text-xs md:text-md font-thin">
                        {{ currentSong.artist.name }}
                    </h2>
                </div>
                <div class="flex-none mx-3">
                    <div class="flex gap-3">
                        <button v-if="currentSong.verse.length" class="text-white text-2xl" @click="toggleVerse"  :class="{
                            'icon-file-text': !isLyrics,
                            'icon-minimize-2': isLyrics,
                        }"></button>
                        <button class="icon-list text-white text-3xl" disabled></button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { Link } from "@inertiajs/vue3";
import {
    nextTick,
    onMounted,
    reactive,
    ref,
    watch
} from "vue";
import NomadPlayer from "@/Layouts/NomadPlayer.js";
import AdsPlayer from "@/components/AdsPlayer.vue";
import Affiliates from "@/components/Affiliates.vue";
const playerInst = ref();
const playState = ref(true);

onMounted(async () => {
    await nextTick();
    initPlayer();
});

function initPlayer() {
    playerInst.value = new NomadPlayer(Math.random());
}

function setVolume() { }

watch(playerInst, (oldval, newval) => {
    //playerInst.value.playAudio()
});

defineExpose({
    /** @description get sound state */
    setVolume,
});
</script>
<script>
import {
    Howl,
    Howler
} from "howler";
import {
    inject,
    defineComponent
} from "vue";
import {
    mapActions,
    mapGetters,
    mapState
} from "vuex";

export default {
    data() {
        return {
            _howl: null,
            progress: 0,
            isPlaying: false,
            isLyrics: false,
            hasVerse: false,
            canDrag: false,
            loading: true
        };
    },
    created() {
        
     },
    mounted() { },
    computed: {
        currentSong() {
            return this.$store.getters["current/currentSong"];
        },
    },
    methods: {
        ...mapActions("current", ["prepareCurrent"]),
        toggleVerse() {
            if (
                
                !this.hasVerse &&
                this.currentSong.verse !== null &&
                this.currentSong.verse
            ) {
                this.hasVerse = true;
                return;
            }

            if (this.hasVerse) this.hasVerse = false;
            if (this.isLyrics) this.isLyrics = false;
        },
        initPlayer() {
            this._howl = new Howl({
                src: this.currentSong.sources[0].src,
                html5: true,
                autoplay: false,
                preload: false,
                format: ["mp3", "mpeg"],
                onload: () => {
                    this.loading = false
                },
                onloaderror: (e, msg) => {
                    console.log(msg);
                    this.loading = false
                },
                onplayerror: (e, msg) => {
                    console.log(msg);
                    this.loading = false
                },
                onplay: () => requestAnimationFrame(this.step.bind(this)),
                onend: () => {
                    this.stopPlay()
                },
                onpause: () => { },
                onrate: () => { },
                onstop: () => { },
                onseek: () => { },
                onfade: () => { },
                onunlock: () => { },
            });

            this._howl.on("play", () => {
                this.trackSongPlay(this.currentSong.content_type, this.currentSong.id, 'play')
                this.isPlaying = true;
            });


            this._howl.on("pause", () => {
                this.trackSongPlay(this.currentSong.content_type, this.currentSong.id, 'pause')
                this.isPlaying = false;
            });

            this._howl.on("load", () => {
                this.trackSongPlay(this.currentSong.content_type, this.currentSong.id, 'load')
                this._howl.play();
            });
        },
        onSeek(e) {
            let sound = this._howl;
            var seekedTo = e.target.valueAsNumber;

            const seekPosition = (seekedTo / 1000) * sound.duration();

            sound.seek(seekPosition)
        },
        onDragging() {
            this.canDrag = !this.canDrag
        },
        trackSongPlay(type, id, eventCategory) {
            // Determine the event label based on the type (song or episode)
            const eventLabel = type === 'SONG' ? 'song_id' : 'episode_id';
            const eventType = type === 'SONG' ? 'Song' : 'Episode'

            // Track the "SongPlay" event with the associated ID
            this.$gtag.event(eventType, {
                event_category: eventCategory, // Event category (you can customize this)
                [eventLabel]: id,       // Use dynamic event label based on type
            });
        },
        mediaController() {
            if ("mediaSession" in navigator) {
                const thumbnail =
                    this.currentSong.cover || this.currentSong.artist.image;
                navigator.mediaSession.metadata = new MediaMetadata({
                    title: this.currentSong.name,
                    artist: this.currentSong.artist.name,
                    album: this.currentSong.artist.name,
                    artwork: [{
                        src: thumbnail,
                        sizes: "96x96",
                        type: "image/png",
                    },
                    {
                        src: thumbnail,
                        sizes: "128x128",
                        type: "image/png",
                    },
                    {
                        src: thumbnail,
                        sizes: "192x192",
                        type: "image/png",
                    },
                    {
                        src: thumbnail,
                        sizes: "256x256",
                        type: "image/png",
                    },
                    {
                        src: thumbnail,
                        sizes: "384x384",
                        type: "image/png",
                    },
                    {
                        src: thumbnail,
                        sizes: "512x512",
                        type: "image/png",
                    },
                    ],
                });

                navigator.mediaSession.setActionHandler("play", () => {
                    this.playPause();
                });
                navigator.mediaSession.setActionHandler("pause", () => {
                    this.playPause();
                });
            }
        },
        play() {
            this.loading = true;
            let player = this._howl;

            if (player.playing()) {
                this._howl.stop();
            }

            this._howl.unload();

            if(this.currentSong.stream && (this.currentSong.stream == 'VOCAL' || this.currentSong.stream == 'MUSIC')) {
                if(this.currentSong.stream == 'VOCAL') {
                    this._howl._src = this.currentSong.smart.vocals;
                }else{
                    this._howl._src = this.currentSong.smart.music;
                }
            } else {
                this._howl._src = this.currentSong.sources[0].src;
            }
            

            this._howl.load();

            this.mediaController();
        },
        playPause() {
            if (this._howl.playing()) this._howl.pause();
            else this.play();
        },
        stopPlay() {
            let player = this._howl;

            this._howl.stop();

            this._howl.unload();
            this.isPlaying = false;
            this.progress = 0
        },
        step() {
            let sound = this._howl;
            let seek = sound.seek() || 0;
            if (!this.canDrag)
                this.progress = (seek / sound.duration()) * 1000 || 0;

            if (sound.playing()) requestAnimationFrame(this.step.bind(this));
        },
    },
    watch: {
        currentSong: {
            handler(old, newval) {
                if (!this._howl) this.initPlayer();
                this.play();
            },
            deep: true,
        },
    },
};
</script>
