<template>
    <div>
        <div v-if="!contents">
            <TilePlaceholder />
            <Carousel :items-to-show="3" :breakpoints="breakpoints" :settings="settings">
                <Slide v-for="event in placeholders" :key="event.id">
                    <EventCardPlaceholder class="m-3 w-full inline-block" />
                </Slide>
            </Carousel>
        </div>
        <div v-else>
            <Tile title="Events" subtitle="upcoming events and venues" />
            <Carousel :items-to-show="3" :breakpoints="breakpoints" :wrapAround="true" :transition="500">
                <Slide v-for="event in contents" :key="event.id">
                    <EventCard :event="event" class="m-3 w-full inline-block" />
                </Slide>
            </Carousel>
        </div>
    </div>
</template>
<script>
import Tile from "@/components/Tile.vue";
import EventCard from "@/components/EventCard.vue";
import { Carousel, Slide, Pagination, Navigation } from "vue3-carousel";
import { EventCardPlaceholder, TilePlaceholder } from "@/components/placeholders/placeholder.js";
export default {
    name: "EventsSlides",
    props: {
        contents: Object,
    },
    data() {
        return {
            placeholders: [{ id: 1 }, { id: 2 }, { id: 2 }, { id: 2 }],
            breakpoints: {
                // 700px and up
                100: {
                    itemsToShow: 1.2,
                    snapAlign: "center",
                },
                700: {
                    itemsToShow: 2.2,
                    snapAlign: "center",
                },
                // 1024 and up
                1024: {
                    itemsToShow: 3,
                    snapAlign: "start",
                },
            },
        };
    },
    components: {
        Carousel,
        Slide,
        Pagination,
        Tile,
        EventCard,
        EventCardPlaceholder,
        TilePlaceholder
    },
};
</script>
