<template>
    <div class="bg-nomad-800 max-w-lg my-3 rounded-md p-3">
        <h1 class="text-nomad-accent uppercase font-light">Featured content</h1>
        <div v-if="contents.length">
            <draggable v-model="contents" tag="transition-group" :component-data="{ name: 'fade' }">
                <template #item="{ element }">
                    <div class="flex items-center gap-2 my-1 w-full bg-nomad-100 rounded-md p-3">
                        <span class="icon-menu"></span>
                        <span>{{ element.name }} <span class="font-thin">{{ element.artist.name }}</span></span>
                        <button class="icon-x text-nomad-900" @click.prevent="removeContent(element.id)"></button>
                    </div>
                </template>
            </draggable>
        </div>
        <div class="relative">
            <div class="flex items-center gap-3">
                <input type="text" v-model="query" class="rounded-md my-3" />
                <button @click.prevent="saveOptions"
                    class="bg-nomad-900 text-nomad-accent rounded-md px-4 h-12 hover:bg-nomad-800">Save</button>
            </div>
            <ul class="absolute bg-nomad-200 text-nomad-800 rounded-md" v-if="results.songs">
                <li v-for="song in results.songs" v-bind:key="song.id"><button @click.prevent="selectContent(song)"
                        class="hover:bg-nomad-700 hover:text-nomad-accent w-full text-left p-1"><span
                            class="icon-music mr-1"></span>{{ song.name }} <span class="font-thin">{{ song.artist.name
                            }}</span></button></li>
                <li v-for="artist in results.artists" v-bind:key="artist.id"><button
                        class="hover:bg-nomad-700 hover:text-nomad-accent w-full text-left p-1"><span
                            class="icon-user mr-1"></span>{{ artist.name }}</button></li>
            </ul>
        </div>
    </div>
</template>
<script>
import { defineAsyncComponent } from 'vue'
import { debounce, remove, take } from 'lodash';

import draggable from 'vuedraggable'
import axios from 'axios';
export default {
    data() {
        return {
            query: null,
            contents: [],
            results: {}
        }
    },
    components: {
        draggable,
    },
    mounted() {
        this.getOptions()
    },
    methods: {
        getContent() {
            if (this.query) {
                axios
                    .get("/api/backstage/admin-search?q=" + this.query)
                    .then((response) => (this.results = response.data));
            }
        },
        selectContent(content) {
            this.contents.push(content);
            this.results = {};
            this.query = null
        },
        saveOptions() {
            axios.post('/api/backstage/options', { 'option_key': 'HOMEFEATURED', 'option_value': take(this.contents, 6) })
        },
        getOptions() {
            axios.get('/api/backstage/options/HOMEFEATURED')
                .then(response => this.contents = response.data.option_value)
        },
        removeContent(id) {
            remove(this.contents, function (n) {
                return n.id === id
            })
        }
    },
    watch: {
        query: debounce(function (newval, oldval) {
            this.getContent(newval);
        }, 1000),
    },
}
</script>