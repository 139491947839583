<template>
    <div class="bg-nomad-800 max-w-lg my-3 rounded-md p-3 text-nomad-200" v-if="song">
        <h1 class="text-nomad-accent uppercase font-light">Playlist Connection</h1>
        <div class="mt-5">
            <ul class="flex flex-col gap-4">
                <li class="flex items-center">
                    <div class="flex-auto">
                        <h2 class="font-bold">{{ song.name }}</h2>
                        <h3>{{ song.artist.name }}</h3>
                        <div v-if="song.featuring"><span v-for="artist in song.featuring">{{ artist.name }}, </span></div>
                    </div>
                    <div>
                        <a :href="'/song/' + song.id" class="icon-external-link text-xl text-nomad-accent" target="_blank"></a>
                    </div>
                </li>
                <li class="flex gap-2" v-if="contents">
                    <button class="bg-nomad-600 rounded-md px-2 text-sm  hover:text-nomad-accent"
                        v-for="playlist in contents" @click.prevent="removeContent(playlist.id)">{{ playlist.name }} ({{
                            playlist.songs_count }}) <span class="icon-x"></span></button>
                </li>
                <li>
                    <input type="text" class="rounded-md border-0" v-model="query" />
                    <div class="relative">
                        <ul class="absolute bg-nomad-200 text-nomad-800 rounded-md" v-if="results">
                            <li v-for="playlist in results" v-bind:key="song.id"><button
                                    @click.prevent="selectContent(playlist)"
                                    class="hover:bg-nomad-700 hover:text-nomad-accent w-full text-left p-1"><span
                                        class="icon-music mr-1"></span>{{ playlist.name }} <span class="font-thin">{{
                                            playlist.name
                                        }}</span></button></li>
                        </ul>
                    </div>
                </li>

                <li>
                    <button @click.prevent="savePlaylistConnection"
                        class="bg-nomad-900 text-nomad-accent rounded-md px-4 h-12 hover:bg-nomad-800">Attach
                        playlist</button>
                </li>

            </ul>

        </div>
    </div>
</template>
<script>
import { debounce, remove } from 'lodash';
export default {
    data() {
        return {
            song: null,
            query: null,
            contents: [],
            results: {}
        }
    },
    created() {
        this.getSong()
    },
    methods: {
        getSong() {
            axios.post('/api/backstage/no-playlist-song')
                .then((response) => {
                    this.song = response.data
                })
        },
        selectContent(content) {
            this.contents.push(content);
            this.results = {};
            this.query = null
        },
        getContent() {
            if (this.query) {
                axios
                    .get("/api/backstage/playlist-search?q=" + this.query)
                    .then((response) => {
                        this.results = response.data
                    });
            }
        },
        removeContent(id) {
            remove(this.contents, function (n) {
                return n.id === id
            })
        },
        savePlaylistConnection() {
            axios.post('/api/backstage/playlist-attach', { 'song': this.song.id, 'playlists': this.contents })
                .then((response) => {
                    this.contents = [],
                        this.results = {};
                    this.query = null
                    this.getSong()
                })
        },
    },
    watch: {
        query: debounce(function (newval, oldval) {
            this.getContent(newval);
        }, 1000),
    },
}
</script>