import api from "@/api";
const CurrentModule = {
    state: () => ({ song: {} }),
    mutations: {
        UPDATE_SONG(state, payload) {
            state.song = payload;
        },
    },
    actions: {
        async prepareCurrent({ commit, dispatch }, payload) {
            const current = await api.CurrentApi.fetchSong(payload);
            let { mp3, sources } = current.data.data

            if (payload.stream) {
                current.data.data.stream = payload.stream
            }

            if (mp3) {
                commit("UPDATE_SONG", current.data.data);
            }
            else {
                dispatch('notification/showNotification', { message: "content not available", type: "info" }, { root: true })
            }
        },
    },
    getters: {
        currentSong(state) {
            return state.song;
        },
    },
    namespaced: true,
};

export default CurrentModule;
