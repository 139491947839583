import axios from 'axios'
const EventApi = {
    async getSingle(id) {
       const content = await axios.get('/api/event/' + id)
       return content
    },
    async getAll() {
        const content = await axios.get('/api/events')
       return content
    }
}

export default EventApi