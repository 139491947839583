<template>
    <div v-if="playlists">
        <div class=" items-center flex rounded-md p-3 mb-3 h-32">
            <div class="text-center m-auto">
                <h1 class="text-white font-thin text-3xl md:text-5xl">PLAYLISTS</h1>
            </div>
        </div>
        <div class="container">
            <ul class="grid grid-cols-2 lg:grid-cols-4 gap-4 mt-10">
                <li v-for="playlist in playlists.data" :key="playlist.id">
                    <Link :title="playlist.name"
                        class="rounded-3xl  bg-nomad-800 text-nomad-50 hover:bg-nomad-accent hover:text-nomad-950 w-full h-28 grid items-center focus:outline-none"
                        :href="'/playlist/' + playlist.id">
                    <div class="text-center">
                        <h3 class="text-center font-medium text-lg">
                            {{ playlist.name }}
                        </h3>
                        <div class="text-sm font-thin">{{ playlist.songs_count}} songs</div>
                    </div>

                    </Link>
                </li>
            </ul>
        </div>
    </div>
</template>
<script setup>
defineProps({ playlists: Object });
</script>
<script>
import { defineAsyncComponent } from 'vue'
import { mapState, mapActions } from "vuex";
import { usePage, Link } from "@inertiajs/vue3";

export default {
    methods: {
        ...mapActions("playlists", ["getAllPlaylist"]),
    },
    beforeMount() {
        document.title = 'Playlists - Music Collection'
    },
    components: {
        'adsense': defineAsyncComponent(() => import("@/components/Adsense.vue")),
    }
};
</script>
