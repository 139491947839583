<template>
    <div class="w-full">
        <div class=" items-center flex rounded-md p-3 mb-3 h-32">
            <div class="text-center m-auto">
                <h1 class="text-white font-thin text-3xl md:text-5xl">{{ playlist.name }}</h1>
                <p class="block text-white mt-2 font-thin" v-if="playlist.songs_count > 0">{{ playlist.songs_count }} songs
                </p>
            </div>
        </div>
        <div>
            <ul class="grid grid-cols-1 lg:grid-cols-4 gap-4">
                <li v-for="song in playlist.songs">
                <CardSong :song="song" />
            </li>
            </ul>
        </div>
    </div>
</template>
<script setup>
defineProps({ playlist: Object });
</script>
<script>
import { defineAsyncComponent } from 'vue'
import { mapState, mapActions } from "vuex";
import { usePage, Link } from "@inertiajs/vue3";
import CardSong from '@/components/CardSong.vue'
export default {
    methods: {
        ...mapActions("playlist", ["getSinglePlaylist"]),
    },
    beforeMount() {
        document.title = this.playlist.name + ' Heeso - Playlist'
    },
    components: {
        'adsense': defineAsyncComponent(() => import("@/components/Adsense.vue")),
        CardSong
    }
};
</script>