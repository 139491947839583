<template>
    <div
        class="w-full hover:bg-nomad-accent text-nomad-200 hover:text-nomad-900 bg-nomad-900 rounded-3xl items-center block flex gap-3 p-2 focus:outline-none" :class="{'bg-nomad-accent text-nomad-900': jumbo}"
    >
        <div class="flex" v-if="jumbo">
            <Link :href="'/song/' + song.id"
                ><img
                    :src="song.cover"
                    :alt="song.name"
                    class="rounded-3xl h-40 md:h-64 object-cover aspect-square"
            /></Link>
        </div>
        <div class="flex" v-else>
            <Link :href="'/song/' + song.id"
                ><img
                    :src="song.cover"
                    :alt="song.name"
                    class="rounded-3xl h-20 md:h-28 object-cover aspect-square"
            /></Link>
        </div>
        <div class="flex-auto">
            <h3 class="font-small lg:font-bold"  :class="{'text-2xl': jumbo}">{{ song.name }}</h3>
            <h4 class="font-light">{{ song.artist.name }}</h4>
            <Featuring :artists="song.featuring" />
        </div>
        <div class="col-span-1 flex gap-3 px-3 text-right">
            <button
                class="icon-heart hover:text-nomad-900 text-xl hover:scale-125 transition-transform"
                :class="{
                    'text-white': !song.is_liked,
                    'text-nomad-900': song.is_liked,
                }"
                aria-label="add to favorites"
            ></button>
            <button
                @click="prepareCurrent({id: song.id, type: 'SONG'})"
                class="icon-play text-white hover:text-nomad-900 text-xl hover:scale-125 transition-transform"
                aria-label="play song"
            ></button>
        </div>
    </div>
</template>
<script setup>
defineProps({ song: Object, jumbo: Boolean });
</script>
<script>
import { mapState, mapActions } from "vuex";
import { Link } from "@inertiajs/vue3";
import Featuring from "@/components/Featuring.vue";
export default {
    components: {
        Link,
        Featuring,
    },
    methods: {
        ...mapActions("favorites", [""]),
        ...mapActions("current", ["prepareCurrent"]),
    },
};
</script>
