<template>
    <div>
        <h4>Timed Lyrics</h4>
        <div class="flex gap-2">
            <div><input type="number" class="rounded-md" /></div>
            <div><input type="text" class="rounded-md" /></div>
        </div>
    </div>
</template>
<script>
export default {
    data() {
        return {
            contents:[]
        }
    }
}
</script>