import { Howl, Howler } from "howler";

class NomadPlayer 
{

    constructor(current){
        this.current = current
        
    }

    play ()  {
    }
}

export default NomadPlayer