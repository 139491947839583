<template>
    <div class="w-full max-w-2xl mx-auto  p-0">
        <div v-if="randomWebsite"
            class="mx-3 px-4 py-2 flex items-center   rounded-3xl gap-3" :style="{backgroundColor:randomWebsite.background }">
            <div><a :href="randomWebsite.url" target="_blank"><img :src="randomWebsite.image"  :alt="randomWebsite.title" class="h-10 w-10 rounded" /></a></div>
            <a :href="randomWebsite.url" target="_blank" class="flex-1">
                <h4 class="font-bold text-nomad-900 text-sm" :style="{color:randomWebsite.color }">{{ randomWebsite.title }}</h4>
                <p class="font-light text-nomad-900 text-sm" :style="{color:randomWebsite.color }" v-if="randomWebsite.descirption">{{ randomWebsite.descirption }}</p>
            </a>
            <a :href="randomWebsite.url" class="max-w-2">
                <span class="icon-external-link text-nomad-900" :style="{color:randomWebsite.color }"></span>
            </a>
        </div>
    </div>
</template>

<script setup>
import { ref, onMounted } from 'vue';

const websites = [
    { id: 10089, title: "Ready to Thrive in the United States Business Scene?", descirption: "Partner with Nexa for expert digital solutions and strategic growth.", url: "https://nexainteractives.com", image: "https://nexainteractives.com/assets/logo.svg", background: "#F8C873", color: "#22223B" },
    { id: 10089, title: "Mrembo Pure Fresh Qasil", descirption: "Shop for Qasil and Qasil Saop.", url: "https://mrembo.co/", image: "https://mrembo.co/cdn/shop/articles/6cba2a12a223266fc02bf6ed43c6d797-xxlarge.jpg?v=1705089524&width=1080", background: "#fff", color: "#0D222F" }
];

const randomWebsite = ref(null);

const getRandomWebsite = () => {
    const randomIndex = Math.floor(Math.random() * websites.length);
    randomWebsite.value = websites[randomIndex];
};

// Load a random website when the component is mounted
onMounted(() => {

    var userAgent = navigator.userAgent;

    if (/android/i.test(userAgent)) {
        websites.push({ id: 10084, title: "Android App", descirption: "Get it on Google Play", url: "https://play.google.com/store/apps/details?id=com.nomadlyrics", image: "https://cdn.nomadlyrics.com/static/artists/jNePjZplFJiOFqWhnO5P64hw62xw1IMBVPngjNyq.png", background: "#000", color: "#fff" })
    }


    if (/iPad|iPhone|iPod/.test(userAgent)) {
        websites.push({ id: 10085, title: "iPhone App", descirption: "Available on the App Store", url: "https://apps.apple.com/app/apple-store/id1503789749?pt=121219390&ct=NomadLyricsWebsite&mt=8", image: "https://cdn.nomadlyrics.com/static/artists/1efZ7Zoq4blti47OUjBcykPlTQi3oL8YySzkMIMJ.png", background: "#000", color: "#fff" })
    }
    getRandomWebsite();
});
</script>