<template>
    <div>
        <Tile title="Latest Episodes" subtitle="popular Somali podcasts" url="/podcasts" />
        
        <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-3">
            <div class="rounded-lg bg-nomad-900 " v-for="episode in podcasts" v-key="episode.id">
                <div class="flex p-2 gap-3 items-center">
                    <div><button class="icon-play rounded-full bg-nomad-accent w-10 h-10 text-nomad-900  top-0 -right-2 focus:outline-none text-xl hover:scale-125 transition-all hover:border border-nomad-accent font-bold" @click="prepareCurrent({id:episode.id, type: 'EPISODE'})" aria-label="Play Episode"></button></div>
                    <div>
                        <p class="font-thin text-xs text-nomad-accent">{{  episode.pubDate }}</p>
                        <h2 class="text-nomad-accent font-medium uppercase text-xs">{{  episode.podcast.title }}</h2>
                        <h3 class="text-light text-nomad-200 font-light"><Link :href="'/episode/' + episode.id"><span class="mr-1 font-thin" v-if="episode.ordering">#{{ episode.ordering }}</span>{{ episode.title }}</Link></h3>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { mapState, mapActions } from "vuex";
import Tile from "@/components/Tile.vue";
import { Link } from "@inertiajs/vue3";
export default {
    props: {
        podcasts: {
            type: Array,
            default: [],
        },
    },
    data() {
        return {

        };
    },
    methods: {
        ...mapActions("current", ["prepareCurrent"]),
    },
    components: {
        Tile,
        Link,
    },
};
</script>
