import api from '@/api'

const FavoritesModule = {
    state: () => ({ all: [] }),
    mutations: {
        ADD_FAVORITE(state, payload) {
            state.all.splice(0, state.all.length, ...payload)
        },
        REMOVE_FAVORITE(state, payload) {
            state.all.push( payload );
        },
    },
    actions: {
        async addToFavorites(context, payload) {
            const song = await api.FavoriteApi.add(payload)
        },
        async getAllFavorites (context) {
            const song = await api.FavoriteApi.all()
            context.commit("ADD_FAVORITE", song.data);
        },
        deteleFavorite(state, payload) {
            context.commit("REMOVE_FAVORITE", payload);
        }
    },
    getters: {

    },
    namespaced: true,
};
export default FavoritesModule;
