<template>
    <div class="bg-nomad-800 max-w-lg my-3 rounded-md p-3">
        <h1 class="text-nomad-accent uppercase font-light">Fix no audio</h1>
        <blockquote class="text-lg font-semibold italic text-center text-nomad-200 my-3">
            About
            <span
                class="before:block before:absolute before:-inset-1 before:-skew-y-3 before:bg-nomad-accent relative inline-block">
                <span class="relative text-nomad-900"  v-if="content">{{ content.status }}</span>
            </span>
            songs are without audio. 
        </blockquote>
        <div class="grid gap-2 my-3" v-if="content">
            <h2 class="text-nomad-100 font-bold  text-2xl">{{ content.song.name }}</h2>
            <h5 class="text-nomad-100 font-normal  text-lg">{{ content.song.artist.name }}</h5>
            <h5 class="text-nomad-100 font-normal  text-lg" v-if="content.song.youtube"><a :href="'https://youtu.be/'+content.song.youtube" target="_blank">{{ content.song.youtube }}</a></h5>
            <h5 class="text-nomad-100 font-normal  text-lg">#{{ content.song.id }}</h5>
            <input type="text" class="rounded-md" placeholder="youtube" v-model="youtube" />
            <div class="flex gap-3">
                <button class="rounded-md bg-nomad-900 p-3 text-nomad-accent" @click="saveYoutube">save</button>
            <button class="rounded-md bg-nomad-900 p-3 text-nomad-accent icon-refresh-ccw" @click="getNoAudio"></button>
            </div>
        </div>
    </div>
</template>
<script>
import axios from 'axios'
import { mapActions } from "vuex";
export default {
    data() {
        return {
            content: null,
            youtube:null
        }
    },
    created() {
        this.getNoAudio()
    },
    methods: {
        ...mapActions("notification", ["showNotification"]),
        getNoAudio() {
            axios.get('/api/backstage/no-audio')
                .then((response) => {
                    this.content = response.data
                })
        },
        saveYoutube() {
            axios.post('/api/backstage/save-youtube', {id: this.content.song.id, youtube: this.youtube})
                .then((response) => {
                    this.youtube = null
                    this.showNotification({
                        message: response.data.message,
                        type: 'info',
                    })
                })
                .catch(error => {
                    this.showNotification({
                        message: "error occurred",
                        type: 'warning',
                    })
                })
        }
    }
}
</script>