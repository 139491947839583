import api from "@/api";

const AlbumModule = {
    state: () => ({ all: [], single: null, isLoading: false }),
    mutations: {
        ADD_ALL_ALBUMS(state, payload) {
            state.all.splice(0, state.all.length, ...payload);
        },
        UPDATE_SINGLE_ALBUM(state, payload) {
            state.single = payload;
        },
        UPDATE_IS_LOADING(state) {
            state.isLoading = !state.isLoading
        }
    },
    actions: {
        async getAllAlbums(context) {
            context.commit("UPDATE_IS_LOADING");
            const content = await api.AlbumApi.getAll();
            context.commit("ADD_ALL_ALBUMS", content.data);
            context.commit("UPDATE_IS_LOADING");
        },
        async getSignleAlbum(context, payload) {
            context.commit("UPDATE_IS_LOADING");
            const content = await api.AlbumApi.getSingle(payload);
            context.commit("UPDATE_SINGLE_ALBUM", content.data);
            context.commit("UPDATE_IS_LOADING");
        },
    },
    getters: {},
    namespaced: true,
};
export default AlbumModule;
