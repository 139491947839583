<template>
    <Container class="w-full">
        <div class="w-full flex my-4 gap-3">
            <Link
                href="/backstage"
                class="text-white rounded-md py-2 px-3 bg-nomad-900 hover:bg-nomad-800"
                >Cancel</Link
            >
        </div>
        <div class="w-full">
            <form @submit.prevent="submit" class="grid gap-4">
                <div>
                    <TextField
                        placeholder="artist name"
                        v-model:text="form.name"
                    />
                </div>
                <div>
                    <TextField
                        placeholder="stage name"
                        v-model:text="form.stage_name"
                    />
                </div>
                <div><ImageUploader v-model="form.image" /></div>
                <div>
                    <select v-model="form.content_type" class="rounded-md">
                        <option value="HSA">Heesaa</option>
                        <option value="GBY">Gabyaa</option>
                        <option value="MJY">Majaajileyste</option>
                        <option value="QRA">Qoraa</option>
                        <option value="JIL">Jilaa</option>
                        <option value="MSK">Musikeyste</option>
                    </select>
                </div>
                <div class="block w-full mb-10">
                    <textarea v-model="form.biography" class="text-white" ></textarea>
                </div>
                <div class="block"><button class="text-nomad-900 bg-nomad-accent text-xl rounded-md py-2 px-4">Create</button></div>
            </form>
            <h6 v-if="form.hasErrors" class="text-nomad-accent my-2">{{ form.errors.name }}</h6>
            <pre class="text-white">{{ form }}</pre>
        </div>
    </Container>
</template>
<script setup>
import { defineAsyncComponent } from 'vue'


import Container from "@/Pages/Backstage/Container.vue";
import TextField from "@/Pages/Backstage/Components/TextField.vue";
import ImageUploader from "@/Pages/Backstage/Components/ImageUploader.vue";

import { useForm, usePage, router, Link } from "@inertiajs/vue3";
import { ref, onMounted } from "vue";

const form = useForm({
    name: null,
    stage_name: null,
    content_type: "HSA",
    image: null,
    biography: null
});

onMounted(() => {
    console.log(usePage().props);
});

function submit() {
    form.post('/backstage/artists')
}
</script>
