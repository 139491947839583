<template>
    <div class="w-64 relative my-3">
        <input type="text" v-model="query" class="rounded-md disabled:bg-slate-50 disabled:text-slate-500"
            :placeholder="placeholder" :disabled="selected_contents.length >= max" />
        <ul v-if="contentType === 'artists'" class="rounded-md overflow-hidden absolute z-10">
            <li v-for="content in contents.artists" v-bind:key="content.id">
                <button class="bg-nomad-100 text-nomad-900 w-full text-left p-2 hover:bg-nomad-200"
                    @click.prevent="selectContent(content)">
                    {{ content.name }}
                </button>
            </li>
        </ul>
        <ul v-if="contentType == 'playlists'"  class="rounded-md overflow-hidden absolute z-10">
            <li v-for="content in contents.playlists" v-bind:key="content.id">
                <button class="bg-nomad-100 text-nomad-900 w-full text-left p-2 hover:bg-nomad-200"
                    @click.prevent="selectContent(content)">
                    {{ content.name }}
                </button>
            </li>
        </ul>
        <ul v-if="selected_contents.length > 0" class="flex flex-wrap gap-2 my-2">
            <li v-for="content in selected_contents" :key="content.id"><button
                    class="rounded-3xl bg-nomad-800 text-nomad-accent px-2 py-2 font-thin text-sm"
                    @click.prevent="removeContent(content.id)"><span class="icon-x mx-1"></span>{{ content.name
                    }}</button></li>
        </ul>
    </div>
</template>
<script>
import { defineAsyncComponent } from 'vue'
import { debounce, remove } from 'lodash';
import axios from "axios";
export default {
    props: {
        type: {
            type: String,
            default: 'artists'
        },
        placeholder: {
            type: String,
            default: 'select artist'
        },
        max: {
            type: Number,
            default: 1
        }
    },
    data() {
        return {
            contentType: this.type,
            query: null,
            contents: {},
            selected_contents: [],
        };
    },
    methods: {
        getContent() {
            if (this.query) {
                axios
                    .get("/api/search?q=" + this.query)
                    .then((response) => (this.contents = response.data));
            }
        },
        selectContent(content) {
            this.selected_contents.push(content);
            this.contents = {};
            this.$emit('update:modelValue', this.selected_contents)
            this.query = null
        },
        removeContent(id) {
            remove(this.selected_contents, function (n) {
                return n.id === id
            })
            this.$emit('update:modelValue', this.selected_contents)
        }
    },
    watch: {
        query: debounce(function (newval, oldval) {
            this.getContent(newval);
        }, 1000),
    },
};
</script>
