<template>
    <div>
        <button @click.prevent="clearThumbnail" class="text-white text-sm font-thin"><span class="icon-x"></span>clear thumbnail</button>
        <ul class="grid grid-cols-2 max-w-sm gap-3 mt-4">
            <li v-for="thumbnail in thumbnailSizes" v-bind:key="thumbnail" class=" w-full">
                <button @click.prevent="setThumbnail(thumbnail)" class="border-2 rounded-lg overflow-hidden w-full" :class="{'border-nomad-accent': thumbnail === selected}">
                    <img :src="'https://img.youtube.com/vi/' + youtube +'/'+thumbnail+'.jpg'" class="w-full" alt="">
                    <span class="text-nomad-300 font-thin text-xd block m-2">{{thumbnail}}</span>
                </button>
            </li>
        </ul>
    </div>
</template>
<script>
export default {
    //<div><YoutubeImage :youtube="form.youtube" v-model="form.cover" /></div>
    props: {
        youtube: {
            type: String
        },
        coversize: {
            type: String
        }
    },
    data() {
        return {
            cover: this.youtube,
            thumbnailSizes: ['hqdefault', 'mqdefault', 'sddefault', 'maxresdefault'],
            selected: this.coversize
        }
    },
    methods: {
        handleChange(event) {

        },
        setThumbnail(thumbnail){
            this.selected = thumbnail
            this.$emit('update:modelValue', 'https://img.youtube.com/vi/' + this.youtube +'/'+thumbnail+'.jpg')
            this.$emit('update:thumbnail', this.selected)
        },
        clearThumbnail() {
            this.$emit('update:modelValue', null)
            this.$emit('update:thumbnail', null)
            this.selected = null
        }
    }
}
</script>