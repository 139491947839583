<template>
    <div class="bg-nomad-800 max-w-lg my-3 rounded-md p-3 flex flex-col gap-3">
        <h1 class="text-nomad-accent uppercase font-light">Youtube Imports</h1>
        <div class=" text-sm">
            <label for="channel" class="text-nomad-100">Select Channel
                <select @change="_getContent" v-model="channelId" class="rounded-md border-0 block text-nomad-900">
                    <option :value="channel.id" v-for="channel in channels" v-bind:key="channel.id">{{ channel.name }}
                    </option>
                </select></label>
        </div>
        <div>
            <ul v-if="videos && videos.items && videos.items.length" class="flex flex-col gap-2">
                <li v-for="video in videos.items" class="flex bg-nomad-900 rounded-lg">
                    <div class="flex items-center p-3 gap-3">
                        <div>
                            <img :src="video.snippet.thumbnails.default.url" alt="" class="w-24 h-16 rounded-md bg-cover">
                        </div>
                        <div class="flex flex-col">
                            <span class="text-nomad-accent text-xs ">{{ video.id.videoId }}</span>
                            <h4 class="text-nomad-100 text-sm">{{ video.snippet.title }}</h4>
                        </div>
                        <div>
                            <button class="text-nomad-accent" @click="_initVideo(video)"><span
                                    class="icon-download"></span></button>
                        </div>
                    </div>
                </li>
            </ul>
        </div>
        <div class="flex gap-3" v-if="videos && videos.items">
            <button
                class="rounded-md border border-nomad-accent py-2 px-3 text-nomad-accent  hover:bg-nomad-accent hover:text-nomad-900"
                @click="_prev" v-if="videos.prevPageToken">prev</button>
            <button
                class="rounded-md border border-nomad-accent py-2 px-3 text-nomad-accent  hover:bg-nomad-accent hover:text-nomad-900"
                @click="_next" v-if="videos.nextPageToken">next</button>
        </div>
        <div class="w-full h-full left-0 top-0 fixed bg-nomad-600/80 backdrop-blur  z-40 text-center px-3" v-if="video">
            <button class="icon-x bg-nomad-900 rounded-full w-8 h-8 text-nomad-accent mt-3" @click="_dismissEdit"></button>
            <div style="height: 600px;"
                class="overflow-scroll max-w-xl bg-nomad-900 rounded-md flex flex-col gap-4 m-auto mt-10 p-3 text-left">
                <h4 class="text-center text-nomad-accent">{{ video.snippet.publishedAt }}</h4>
                <p class="text-nomad-200 text-sm">{{ video.snippet.description }}</p>
                <form @submit.prevent="_saveImport" class="w-full">
                    <label for="name"><span class="text-white pb-2">Name</span>
                        <input type="text" id="name" class="w-full border-0 rounded-md my-2" v-model="form.name" />
                    </label>
                    <label for="video"><span class="text-white pb-2">Video ID</span>
                        <input type="text" id="video" class="w-full border-0 rounded-md my-2" v-model="form.youtube" />
                    </label>
                    <div>
                        <div class="my-3"><button class="text-nomad-accent" @click.prevent="_clearCover"><span
                                    class="icon-x"></span> clear</button></div>
                        <ul class="flex gap-3">
                            <li v-for="thumbnail in video.snippet.thumbnails" class="w-28">
                                <button @click.prevent="_updateCover(thumbnail.url)"><img :src="thumbnail.url" alt=""
                                        class="border-2 rounded-lg"
                                        :class="{ 'border-nomad-accent': thumbnail.url == form.cover }" /><span
                                        class="text-white font-thin text-xs">{{ thumbnail.width }} x {{ thumbnail.height
                                        }}</span></button>
                            </li>
                        </ul>
                    </div>
                    <label for="name"><span class="text-white pb-2">Artist</span>
                        <Selector type="artists" v-model="form.artist" placeholder="select artist" />
                    </label>
                    <label for="name"><span class="text-white pb-2">Featured</span>
                        <Selector type="artists" v-model="form.features" placeholder="select artist" max="4" />
                    </label>
                    <label>
                        <input type="number" v-model="form.released" class="rounded-md" placeholder="released" />
                    </label>
                    <label for="is_audio_download" class="text-white"> <input type="checkbox"
                            class="rounded appearance-none" id="is_audio_download" v-model="form.meta.is_youtube"
                            value="1" /> <span>download audio </span></label>
                    <label for="is_smart_music" class="text-white"> <input type="checkbox" class="rounded appearance-none"
                            id="is_smart_music" v-model="form.meta.is_smart" value="1" /> <span>Smart music? </span></label>
                    <div class="my-4"><button type="submit"
                            class="rounded-md border text-nomad-accent hover:text-nomad-900 px-3 py-2 border-nomad-accent hover:bg-nomad-accent">Save</button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</template>
<script>
import axios from 'axios'
import Selector from "@/Pages/Backstage/Components/Selector.vue";
import { mapActions } from "vuex";
import { useForm, usePage } from "@inertiajs/vue3";
export default {
    data() {
        return {
            video: null,
            videos: {},
            page: null,
            channelId: null,
            form: {
                name: null,
                artist: null,
                original_artist: null,
                features: null,
                released: null,
                youtube: null,
                cover: null,
                meta: {
                    thumbnail: null,
                    is_youtube: true,
                    cover: false,
                    retry: false,
                    is_smart: false
                },
            },
            channels: [
                {
                    id: "UCfJ23rBnyQok1NV2e0MIEVg",
                    name: "Iftin Band - Salah Bonge"
                },
                {
                    id: "UCXTzhbfY6viUli-7v_V7RoA",
                    name: 'Hawraar Music'
                },
                {
                    id: "UC371IO_78acNOoN2y1XjMYg",
                    name: 'ABDI ASKARI TUBE'
                },
                {
                    id: "UCucSbMQ70w1nNpekMFe6RKQ",
                    name: "Yurub Geenyo Official"
                },
                {
                    id: "UC86UcBB1qofuGk9as8IhpMQ",
                    name: "Waqal Studio"
                },
                {
                    id: "UCBhPZ6lnTfQZn0bvARil-Xg",
                    name: "Sharma Boy"
                },
                {
                    id: "UCFK3HgpT_Ifb7F8fOcWh2qA",
                    name: "Tusmo Films"
                },
                {
                    id: "UC4aCTV9PWmC9_89kxpzAgpA",
                    name: "Liibaan Jama Studio"
                },
                {
                    id: "UCJ__mZ6PT-0Ikj_LxpaKvtg",
                    name: "Big Moha"
                },
                {
                    id: "UCAON8efxKNHWey6UsFfSGtA",
                    name: "Doni B"
                },
                {
                    id: "UCxCDYZ_-DenztEWJ8UXzyJw",
                    name: "Gantaal Music"
                },
                {
                    id: "UCGC5B6_0C64CwilEb5pjBaw",
                    name: "Haataf Abdi"
                },
                {
                    id: "UC6jptXRFeEcumaH5BPvE-HA",
                    name: "Halimo Gobaad Official"
                },
                {
                    id: "UCmnYJDjq6XU4tVIvdgpv33Q",
                    name: "Hillaac Music"
                },
                {
                    id: "UCppbbj2vr429AeoSAQOq6hw",
                    name: "Hodan & Mursal Channel"
                },
                {
                    id: "UCFPaq219XXXpJKB7MnFe4gg",
                    name: "ISMA IP"
                },
                {
                    id: "UCrqvFSSLLQ0R98kUI6FTGEQ",
                    name: "Jay King Official"
                },
                {
                    id: "UCYKUq58zPgRATf7WElMRQZA",
                    name: "Kiin Jamac"
                },
                {
                    id: "UCdDg_Cj9Bz_tDB0m0Y0Zw5Q",
                    name: "LEEWAAYE STUDIO"
                },
                {
                    id: "UCIhMJeNWmEpcOP_FvqSh6iQ",
                    name: "Naji Productions"
                },
                {
                    id: "UCXxsHtPMKFFsSBuCC9ctaYA",
                    name: "Najma Nashaad Official"
                },
                {
                    id: "UCoV1HJor9gl-XK9K5bVgJjA",
                    name: "Princess Istaahil"
                },
                {
                    id: "UCUvDDOhYK3dxcQg2oYIiMNQ",
                    name: "Sabriina Musse"
                },
                {
                    id: "",
                    name: ""
                }
            ]
        }
    },
    components: {
        Selector
    },
    methods: {
        ...mapActions("notification", ["showNotification"]),
        _getContent() {

            let params = { params: { channelId: this.channelId } };
            if (this.page) {
                params.params.pageToken = this.page
            }
            axios.get('/api/backstage/import-youtube', params)
                .then(response => {
                    this.videos = response.data
                })
        },
        _initVideo(video) {
            this.video = video
            this.form.name = video.snippet.title.toLowerCase()
            this.form.youtube = video.id.videoId
        },
        _dismissEdit() {
            this.video = null;
            this.form = {
                name: null,
                artist: null,
                original_artist: null,
                features: null,
                released: null,
                youtube: null,
                cover: null,
                meta: {
                    thumbnail: null,
                    is_youtube: true,
                    cover: false,
                    retry: false,
                    is_smart: false
                },
            }
        },
        _updateCover(cover) {
            this.form.cover = cover;
        },
        _clearCover() {
            this.form.cover = null;
        },
        _saveImport() {
            const form = useForm(this.form)
            form.post('/api/backstage/song-import-youtube')
                .then(response => {
                    this.showNotification({
                        message: 'Song added',
                        type: 'info',
                    })
                })
                .catch(error => {
                    this.showNotification({
                        message: "error occurred",
                        type: 'warning',
                    })
                })
        },
        _next() {
            this.page = this.videos.nextPageToken
            console.log(this.page)
            this._getContent()
        },
        _prev() {
            this.page = this.videos.prevPageToken
            this._getContent()
        }

    }
}
</script>