<template>
    <Container>
        <div class="w-full flex my-4 gap-3">
            <Link
                href="/backstage/pages/create"
                class="text-nomad-accent rounded-md py-2 px-3 bg-nomad-700 hover:bg-nomad-600"
                >Create</Link
            >
            <Link
                href="/backstage"
                class="text-white rounded-md py-2 px-3 bg-nomad-900 hover:bg-nomad-800"
                >Cancel</Link
            >
        </div>
        <div class="w-full">
            <ul v-if="pages && pages.data" class="grid gap-3">
                <li
                    v-for="page in pages.data"
                    :key="page.id"
                    class="p-3 bg-nomad-900 rounded-3xl text-white"
                >
                    <Link :href="'/backstage/pages/' + page.id + '/edit'">
                        <h2 class="text-xl text-nomad-200">
                            {{ page.title }}
                        </h2>
                        <span class="text-nomad-600 mr-2">{{ page.updated_at }}</span>
                    </Link>
                </li>
            </ul>
        </div>
    </Container>
</template>
<script setup>
import Container from "@/Pages/Backstage/Container.vue";
import { Link, usePage } from "@inertiajs/vue3";
const { pages } = usePage().props;
</script>