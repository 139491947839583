<template>
    <div
        class="rounded-3xl  bg-cover bg-no-repeat bg-center "
    >
        <div class="bg-nomad-900/60 pb-10">
            <div class="flex">
                <Tile title="Trending" subtitle="currently playing by others" />
                <div v-if="current && current.id" class="flex items-center flex-grow">
                    <div>
                        <h3 class="text-nomad-accent font-light text-right">{{ current.name }}</h3>
                    </div>
                    <div><button class="icon-play rounded-full bg-nomad-accent hover:scale-125 transition-all w-10 h-10 m-3 focus:outline-none" @click="playSong"></button></div>
                    
                </div>
            </div>
            <Carousel ref="trendingCarousel" :settings="settings" :breakpoints="breakpoints" v-if="songs.length">
                <Slide v-for="song in songs" :key="song.song_id">
                    <button
                        @click="handleSlideStart(song.song)"
                        class="h-20 rounded-3xl items-center grid text-nomad-50 hover:text-nomad-accent bg-nomad-800 backdrop-blur-lg w-64 "
                    >
                        <div>
                            <h1 class="font-medium">{{ song.song.name }}</h1>
                            <h6 class="font-light text-sm">
                                {{ song.song.artist.name }}
                            </h6>
                        </div>
                    </button>
                </Slide>
            </Carousel>
            <Carousel ref="trendingCarousel" :settings="settings" :breakpoints="breakpoints" v-else>
                <Slide v-for="song in [{id:1},{id:2},{id:3}]" :key="song.id">
                    <button
                        
                        class="h-20 rounded-3xl items-center grid bg-nomad-100/30 backdrop-blur-lg w-64 focus:outline-none"
                    >
                        <div>
                            <h1 class="font-medium"></h1>
                            <h6 class="font-light"></h6>
                        </div>
                    </button>
                </Slide>
            </Carousel>
        </div>
    </div>
</template>
<script setup>
import { Carousel, Slide, Pagination, Navigation } from "vue3-carousel";
import { ref } from "vue";
import { mapActions, mapGetters, useStore } from "vuex";
import Tile from '@/components/Tile.vue'
const props = defineProps({ songs: {type: Array, default: []} });
const trendingCarousel = ref(null)
const background = ref('https://nomadlyrics.sfo2.cdn.digitaloceanspaces.com//static/artists/yK1Z158feMMYMRRLO462F7cuM1EiGWSLIRwH3BWG.jpg')

const current = ref()
const store = useStore()

if(props.songs.length > 0)
    current.value = props.songs[0].song

const settings = {
    itemsToShow: 1.2,
    snapAlign: "center",
};

const breakpoints = {
    700: {
        itemsToShow: 2.2,
        snapAlign: "center",
    },
    1024: {
        itemsToShow: 4.2,
        snapAlign: "start",
    },
};

function playSong() {
    store.dispatch('current/prepareCurrent', {id: current.value.id, type: 'SONG'})
}

function handleSlideStart(event) {
    current.value = event
    if(event.cover)
        background.value = event.cover
    else
        background.value = event.artist.image
}
</script>
