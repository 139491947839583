<template>
    <div>
        <Tile title="Playlists" subtitle="popular playlists" url="/playlists" />
        <Carousel :settings="settings" :breakpoints="breakpoints">
            <Slide v-for="playlist in playlists" :key="playlist.id" class="">
                <Link
                    class="rounded-3xl bg-nomad-800 w-full h-28 grid items-center mr-3 ml-1 focus:outline-none text-nomad-accent"
                    :href="'/playlist/' + playlist.id"
                >
                    <h3 class="text-center font-light text-xl text-nomad-accent">
                        {{ playlist.name }}
                        <span class="block text-nomad-100 text-sm">{{ playlist.songs_count }} songs</span>
                    </h3>
                </Link>
            </Slide>
        </Carousel>
    </div>
</template>
<script setup>
import { Carousel, Slide, Pagination, Navigation } from "vue3-carousel";
import { Link } from "@inertiajs/vue3";
import Tile from "@/components/Tile.vue";

const settings = {
    itemsToShow: 1.2,
    snapAlign: "center",
};
const breakpoints = {
    700: {
        itemsToShow: 2.2,
        snapAlign: "center",
    },
    1024: {
        itemsToShow: 4.2,
        snapAlign: "start",
    },
};
defineProps({ playlists: Array });
</script>
