import api from '@/api'

const JustAddedModule = {
    state: () => ({ all: [] }),
    mutations: {
        ADD_JUST_ADDED(state, payload) {
            state.all.splice(0, state.all.length, ...payload)
        },
    },
    actions: {
        async getAllJustAdded (context) {
            const contents = await api.JustAddedApi.getAll()
            context.commit("ADD_JUST_ADDED", contents.data.data);
        },
    },
    getters: {

    },
    namespaced: true,
};
export default JustAddedModule;
