<script setup>
import { useForm, router, usePage } from "@inertiajs/vue3";
import axios from "axios";
const props = defineProps({image: String})
const form = useForm({
    image: null,
    imagepath: "artists",
    component: usePage().component,
});

function uploadImage($event) {
    let data = $event.target.files[0];
    form.image = data;
    form.post("/api/backstage/image-uploader", {
        forceFormData: true,
        preserveState: true,
        onSuccess: (response) => {
            emit("update:modelValue", '/' + response.props.image);
            form.reset()
        },
        onError: (error) => console.log(error),
    });
}

const emit = defineEmits(["update"]);
</script>

<template>
    <div>
        <input
            type="file"
            @input="uploadImage"
            class="block text-lg font-thin overflow-hidden text-nomad-300 border border-gray-300 rounded-lg cursor-pointer bg-nomad-900 focus:outline-none"
        />
        <progress
            class="bg-nomad-accent text-xs leading-none py-1 text-center text-white"
            v-if="form.progress"
            :value="form.progress.percentage"
            max="100"
        >
            {{ form.progress.percentage }}%
        </progress>
        <img :src="props.image" class="w-48 my-3 rounded-md" v-if="props.image" alt="artist picture">
    </div>
</template>
