import axios from 'axios'
const UserApi = {
    async getUser(id) {
       const user = await axios.get('/api/user')
       return user
    },
    async logoutUser() {
        await axios.post('/logout-user')
        localStorage.removeItem('_token')
    }
}

export default UserApi