<template>
    <div>
        <input type="text" :placeholder="placeholder" v-model="title" @input="handleChange" class="rounded-md border-0" />
    </div>
</template>
<script>
export default {
    props: {
        text: String,
        placeholder: String
    },
    data() {
      return {
        title: this.text
      }
    },
    emits: ["update:text"],
    methods: {
        handleChange(event) {
            this.$emit("update:text", event.target.value);
        },
    },
};
</script>
