<template>
    <div v-if="genre">
        <div class=" items-center flex rounded-md p-3 mb-3 h-32">
            <div class="text-center m-auto">
                <h1 class="text-white font-thin text-3xl md:text-5xl">{{ genre.name }}</h1>
                <p class="block text-white mt-2 font-thin" v-html="genre.description"></p>
            </div>
        </div>
        <hr class="w-20 mx-auto" :style="{ borderColor: genre.color }" />
        <div>
            <ul class="grid gap-4 mt-10">
                <li v-for="song in genre.songs" :key="song.id">
                    <CardSong :song="song" />
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
import { defineAsyncComponent } from 'vue'
import { Link, usePage } from "@inertiajs/vue3";
import { mapState, mapActions, mapGetters } from "vuex";
import CardSong from '@/components/CardSong.vue'
export default {
    created() {
        const id = usePage().props.query.id;
        this.getSignleGenre(id);
    },
    methods: {
        ...mapActions("genre", ["getSignleGenre"]),
    },
    computed: {
        ...mapState({ genre: (state) => state.genre.single }),
    },
    components: {
        CardSong,
        'adsense' : defineAsyncComponent(() => import("@/components/Adsense.vue")),
    },
    mounted() {
        document.title = genre.name + " Genre"
    },
}
</script>