<template>
    <Container class="w-full">
        <div class="w-full">
            <div class="w-full">
            <form @submit.prevent="submit" class="grid gap-4">
                <div>
                    <TextField
                        placeholder="artist name"
                        v-model:text="form.name"
                    />
                </div>
                <div>
                    <TextField
                        placeholder="stage name"
                        v-model:text="form.stage_name"
                    />
                </div>
                <div><ImageUploader v-model="form.image" :image="form.image" /></div>
                <div>
                    <select v-model="form.content_type" class="rounded-md">
                        <option value="HSA">Heesaa</option>
                        <option value="GBY">Gabyaa</option>
                        <option value="MJY">Majaajileyste</option>
                        <option value="QRA">Qoraa</option>
                        <option value="JIL">Jilaa</option>
                        <option value="MSK">Musikeyste</option>
                    </select>
                </div>
                <div class="block w-full mb-10">
                    <textarea v-model="form.biography" class="text-white" ></textarea>
                </div>
                <div class="block"><button class="text-nomad-900 bg-nomad-accent text-xl rounded-md py-2 px-4">Update</button></div>
            </form>
            <h6 v-if="form.hasErrors" class="text-nomad-accent my-2">{{ form.errors.name }}</h6>
            <pre class="text-white">{{ form }}</pre>
        </div>
        </div>
    </Container>
</template>
<script setup>
import { defineAsyncComponent } from 'vue'


import TextField from "@/Pages/Backstage/Components/TextField.vue";
import ImageUploader from "@/Pages/Backstage/Components/ImageUploader.vue";
import Container from "@/Pages/Backstage/Container.vue";
import { usePage, useForm } from "@inertiajs/vue3";
import { onMounted, ref } from "vue";
const data = usePage().props.artist;

const form = useForm({
    name: data.name,
    stage_name: data.stage_name,
    content_type: data.content_type,
    image: data.image,
    biography: data.biography,
    _method: 'PUT'
});

onMounted(() => {
    
});

function submit() {
    form.put('/backstage/artists/' + data.id)
}
</script>
