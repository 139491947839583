import api from "@/api";

const EpisodeModule = {
    state: () => ({ all: [], single: null, isLoading: false }),
    mutations: {
        ADD_ALL_PODCASTS(state, payload) {
            state.all.splice(0, state.all.length, ...payload);
        },
        UPDATE_SINGLE_EPISODE(state, payload) {
            state.single = payload;
        },
    },
    actions: {
        async getAllPodcasts(context) {
            const content = await api.PodcastApi.getAll();
            context.commit("ADD_ALL_PODCASTS", content.data);
        },
        async getSignleSingle(context, payload) {
            const content = await api.EpisodeApi.getSingle(payload);
            context.commit("UPDATE_SINGLE_EPISODE", content.data);
        },
    },
    getters: {},
    namespaced: true,
};
export default EpisodeModule;