<template>
    <div class="inline-flex rounded-md shadow-sm">
        <button @click="toggleDropdown"
            class="group block-inline text-nomad-900 flex text-left items-center rounded-full gap-2 bg-nomad-accent/80 px-3 py-1 hover:bg-nomad-accent hover:scale-110 transition-all"><span
                class="icon-chevron-down"></span> smart play</button>
                <transition enter-active-class="transition ease-out duration-100" enter-from-class="transform opacity-0 scale-95" enter-to-class="transform opacity-100 scale-100" leave-active-class="transition ease-in duration-75" leave-from-class="transform opacity-100 scale-100" leave-to-class="transform opacity-0 scale-95">
        <ul v-if="showDropdown" class="z-50 rounded-lg bg-nomad-00 absolute p-3 flex flex-col gap-3 shadow-xl shadow-yellow-500/20 bg-nomad-900 transition-all">
            <li><button @click="prepareCurrent({ id: song.id, type: 'SONG' })"
                    class="group block-inline bg-nomad-accent rounded-full text-nomad-900 items-center px-3 py-1 w-full  hover:scale-110 transition-all"><span
                        class="icon-play"></span> play</button></li>
            <li><button @click="prepareCurrent({ id: song.id, type: 'SONG', stream: 'VOCAL' })"
                    class="group block-inline bg-red-500 rounded-full text-nomad-900 items-center px-3 py-1 w-full hover:scale-110 transition-all"><span
                        class="icon-mic"></span>vocals only</button></li>
            <li><button @click="prepareCurrent({ id: song.id, type: 'SONG', stream: 'MUSIC' })"
                    class="group block-inline bg-black rounded-full text-nomad-100 items-center px-3 py-1 w-full hover:scale-110 transition-all"><span
                        class="icon-music"></span> music only</button></li>
        </ul></transition>
    </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
export default {
    props:{
        song: {
            type:Object
        }
    },
    data() {
        return {
            showDropdown: false,
        };
    },
    methods: {
        ...mapActions("current", ["prepareCurrent"]),
        toggleDropdown() {
            this.showDropdown = !this.showDropdown;
        },
        hideDropdown(event) {
            if (!this.$el.contains(event.target)) {
                this.showDropdown = false;
            }
        },
    },
    mounted() {
        document.addEventListener('click', this.hideDropdown);
    },
    beforeDestroy() {
        document.removeEventListener('click', this.hideDropdown);
    },
}
</script>