<template>
    <Container>
        <div class="w-full flex my-4 gap-3">
            <Link
                href="/backstage/pages"
                class="text-white rounded-md py-2 px-3 bg-nomad-900 hover:bg-nomad-800"
                >Cancel</Link
            >
        </div>
        <form @submit.prevent="submit" class="grid gap-3 max-w-md">
            <div>
                <input
                    type="text"
                    v-model="form.title"
                    class="rounded-md w-full"
                    placeholder="title"
                />
            </div>
            <div class="block w-full mb-10">
                <textarea
                    v-model="form.content"
                    class="text-black rounded-lg"
                ></textarea>
            </div>
            <div class="block">
                <button class="text-nomad-900 bg-nomad-accent text-xl rounded-md py-2 px-4">Create page</button>
            </div>
        </form>
        <pre class="text-nomad-100">{{ form }}</pre>
    </Container>
</template>
<script setup>
import Container from "@/Pages/Backstage/Container.vue";
import { Link, usePage, useForm } from "@inertiajs/vue3";

const props = usePage().props;
const form = useForm({
    title: null,
    content: null,
});

function submit() {
    form.post("/backstage/pages");
}
</script>
