<template>
    <div v-if="podcast">
        <div class=" items-center flex rounded-md p-3 mb-3 h-32">
                <div class="text-center m-auto">
                    <h1 class="text-white font-thin text-3xl md:text-5xl">{{ podcast.title }}</h1>
                    <p class="block text-white mt-2 font-thin" v-html="podcast.host"></p>
                    <h6 class="text-sm text-nomad-400 font-thin">{{ podcast.episodes_count }} Episodes</h6>
                </div>
            </div>
        <div class="grid grid-cols-1 lg:grid-cols-3 gap-4 my-3">
            
            <div class="flex gap-3 flex-col">
                <img :src="podcast.image" alt="" class="rounded-3xl w-full">
                <hr class="border-nomad-accent border-1 w-20 mx-auto" />
                <p class="text-lg text-nomad-200 font-normal">{{ podcast.description }}</p>
            </div>
            <div class="md:col-span-2 flex flex-col gap-2">
                <hr class="border-nomad-accent border-1" />
                <ul class="w-full list-none divide-y">
                    <li v-for="episode in podcast.episodes" v-bind:key="episode.id">
                        <episode-card :episode="episode" />
                    </li>
                </ul>
            </div>
        </div>
    </div>
</template>
<script setup>
defineProps({ podcast: Object });
</script>
<script>
import { defineAsyncComponent } from 'vue'
import { mapState, mapActions } from "vuex";
import { usePage } from "@inertiajs/vue3";
import EpisodeCard from "@/components/EpisodeCard.vue";
export default {
    methods: {
        ...mapActions("podcast", ["getSignlePodcast"]),
    },
    beforeMount() {
        document.title = this.podcast.title + ' by ' + this.podcast.host
    },
    components: {
        EpisodeCard,
        'adsense' : defineAsyncComponent(() => import("@/components/Adsense.vue")),
    }
};
</script>
