import api from '@/api'
const HomepageModule = {
    state: () => ({ all: {} }),
    mutations: {
        UPDATE_HOMEPAGE(state, payload) {
            state.all = payload
        }
    },
    actions: {
        async prepareHomepage(context, payload) {
            const content = await api.HomepageApi.fetchAll(payload)
            context.commit('UPDATE_HOMEPAGE', content.data)
        }
    },
    getters: {

    },
    namespaced: true,
}

export default HomepageModule