import api from "@/api";

const PodcastModule = {
    state: () => ({ all: [], single: null, isLoading: false }),
    mutations: {
        ADD_ALL_PODCASTS(state, payload) {
            state.all.splice(0, state.all.length, ...payload);
        },
        UPDATE_SINGLE_PODCAST(state, payload) {
            console.log(payload)
            state.single = payload;
        },
    },
    actions: {
        async getAllPodcasts(context) {
            const content = await api.PodcastApi.getAll();
            context.commit("ADD_ALL_PODCASTS", content.data);
        },
        async getSignlePodcast(context, payload) {
            const content = await api.PodcastApi.getSingle(payload);
            context.commit("UPDATE_SINGLE_PODCAST", content.data);
        },
    },
    getters: {},
    namespaced: true,
};
export default PodcastModule;