<template>
    <div>
        <ul class="flex text-sm font-thin gap-2" v-if="artists.length > 0">
            <li>ft</li>
            <li v-for="(artist, index) in artists" :key="artist.id"><Link :href="'/artist/' + artist.id">{{artist.name}}</Link><span v-if="index !== artists.length - 1">, </span></li>
        </ul>
    </div>
</template>
<script setup>
defineProps({ artists: Object });
</script>
<script>
import { Link } from "@inertiajs/vue3";
export default {
    components: {
        Link,
    },
};
</script>