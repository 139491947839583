<template>
    <div
        class="w-full text-white h-20 grid grid-cols-12 gap-4 items-center border-b border-nomad-accent bg-nomad-primary fixed top-0 z-30"
    >
        <div class="col-span-2 text-center text-2xl">
            <button
                @click="toggleMenu"
                class="text-white icon-menu"
                aria-label="open menu"
            ></button>
        </div>
        <div class="col-span-8 text-center">
            <Link href="/" class="inline-block" aria-label="logo"
                ><img src="@/assets/logo.svg" alt="" class="h-12 w-12 m-auto"
            /></Link>
        </div>
        <div class="col-span-2 text-center text-2xl">
            <Link
                href="/search"
                class="icon-search"
                aria-label="site search"
            ></Link>
        </div>
    </div>
    <div
        class="flex h-full w-full fixed z-50 top-0 bg-nomad-900/70 backdrop-blur-sm ease-in-out duration-200"
        :class="{ '-translate-x-0': isOpen, '-translate-x-full': !isOpen }"
    >
        <div
            class="flex-non w-64 ease-in-out duration-300 bg-nomad-900"
            :class="{ '-translate-x-0': isOpen, '-translate-x-full': !isOpen }"
        >
            <ul class="grid gap-1 font-thin text-xl mt-10">
                <li class="h-22 text-center py-3" v-if="auth && auth.id">
                    <img
                        :src="auth.avatar"
                        alt="profile picture"
                        class="rounded-full w-16 h-16 m-auto"
                    />
                    <h3 class="text-md text-nomad-300">{{ auth.name }}</h3>
                    <span class="text-sm text-nomad-400">{{
                        auth.created_at
                    }}</span>
                </li>
                <li
                    class="h-12 flex items-center p-3"
                    v-for="menu in menus"
                    v-bind:key="menu.permlink"
                >
                    <Link
                        v-if="!menu.target"
                        :href="'/' + menu.permlink"
                        class="block transition-all hover:font-light text-white no-decoration"
                        @click="toggleMenu"
                        ><span class="mr-3" :class="menu.icon"></span
                        >{{ menu.title }}</Link
                    >
                    <a :href="menu.permlink" target="_blank" class="block transition-all hover:font-light text-white no-decoration"  v-else><span class="mr-3" :class="menu.icon"></span
                        >{{ menu.title }}</a>
                </li>
                <li
                    class="h-12 bg-nomad-accent flex items-center p-3"
                    v-if="!isLoggedIn"
                >
                    <a
                        class="block transition-all hover:font-light text-nomad-900 no-decoration"
                        href="/auth/google"
                        ><span class="mr-3 icon-user"> </span>Login</a
                    >
                </li>
                <li class="h-12 bg-nomad-accent flex items-center p-3" v-else>
                    <a
                        class="block transition-all hover:font-light text-nomad-900 no-decoration"
                        href="/auth/google"
                        @click.prevent="logoutUser"
                        ><span class="mr-3 icon-user-x"> </span>Logout</a
                    >
                </li>
            </ul>
            <ul
                class="grid gap-1 font-thin text-xl"
                v-if="auth && auth.id && auth.isAdmin"
            >
                <li class="h-12 bg-nomad-accent/80 flex items-center p-3">
                    <Link
                        href="/backstage"
                        class="block transition-all hover:font-light text-nomad-900 no-decoration"
                        @click="toggleMenu"
                        >Backstage</Link
                    >
                </li>
            </ul>
        </div>
        <div class="flex-auto">
            <button
                @click.self="toggleMenu"
                aria-label="close menu"
                class="icon-x text-white text-3xl m-6 z-50"
            ></button>
        </div>
    </div>
</template>
<script setup>
import { computed } from "vue";
import { usePage } from "@inertiajs/vue3";
</script>
<script>
import { Link } from "@inertiajs/vue3";
import { mapState, mapActions } from "vuex";
import CardApps from "@/components/CardApps.vue";
export default {
    data() {
        return {
            isOpen: false,
            menus: [
                { permlink: "search", title: "Search", icon: "icon-search" },
                { permlink: "just-added", title: "Just added", icon: "icon-music" },
                {permlink: "playlists", title: "Playlists",icon: "icon-bar-chart-2" },
                { permlink: "podcasts", title: "Podcasts", icon: "icon-mic" },
                { permlink: "smart", title: "Smart Music", icon: "icon-sliders" },
                { permlink: "favorites", title: "Likes", icon: "icon-heart" },
                { permlink: "pages/apps", title: "Downlaod App", icon: "icon-download" },
                { permlink: "stories", title: "Local News",icon: "icon-circle text-nomad-red" },
                { permlink: "https://www.buymeacoffee.com/kaym.dev", target: true, title: "Support us", icon: "icon-coffee" },
                { permlink: "https://status.nomadlyrics.com/", target: true, title: "Service Status", icon: "icon-activity" },
            ],
        };
    },
    computed: mapState({
        auth: (state) => state.user.auth,
        isLoggedIn: (state) => state.user.isLoggedIn,
    }),
    methods: {
        ...mapActions("user", ["logoutUser"]),
        toggleMenu() {
            this.isOpen = !this.isOpen;
        },
    },
    components: {
        Link,
        CardApps
    },
};
</script>
