<template>
    <Container>
        <div class="w-full flex my-4 gap-3">
            <Link
                href="/backstage/playlist"
                class="text-white rounded-md py-2 px-3 bg-nomad-900 hover:bg-nomad-800"
                >Cancel</Link
            >
        </div>
        <form @submit.prevent="submit" class="grid gap-3 max-w-md">
            <div>
                <input
                    type="text"
                    v-model="form.name"
                    class="rounded-md w-full"
                    placeholder="playlist name"
                />
            </div>
            
            <div class="block">
                <button class="text-nomad-900 bg-nomad-accent text-xl rounded-md py-2 px-4">Create playlist</button>
            </div>
        </form>
        <pre class="text-nomad-50">{{ form }}</pre>
    </Container>
</template>
<script setup>
import Container from "@/Pages/Backstage/Container.vue";
import { Link, usePage, useForm, router } from "@inertiajs/vue3";



const props = usePage().props;
const form = useForm({
    name: null,
});

function submit() {
    form.post("/backstage/playlist", {
       // onSuccess: () => router.visit('/backstage/playlist/' + response.data.id + '/edit'),
    })
        
}
</script>
